export default {
    account: {
        more: {
            service: "線上客服",
            moreMin: "不得小於最低提現金額",
            placePrefix: "請選擇國家區號",
        },
        aboutUs: {
            lint1: "在IDEX，我們對投資的看法有所不同。 當今的金融體系複雜、排外且昂貴，這讓普通人很難開始投資。",
            lint2: "囙此，我們正在構建一個沒有複雜障礙的平臺，一個使您能够看到新的金錢可能性的平臺，並幫助它們成為現實。",
            lint3: "IDEX旨在讓每個人都能進行投資，所以從第一天起，我們的目標是通過簡單易用的金融產品使新手或者經驗豐富的專家都能够投資於他們想要的加密貨幣。",
            lint4: "IDEX嚴格遵守各地區法律法規。 我們根據各種VASP注册以及我們所有覈心市場中的MiFID II、電子錢和PSD II許可證來運營我們的業務。",
            lint5: "IDEX是發展最快的金融科技公司之一，我們擁有世界一流的專家團隊和最優秀的科技人才，以此保障用戶的數位資產從來都只屬於自己，以此鞏固我們在加密貨幣交易市場中的地位。",
        },
        billTypes: [
            {v: 101, name: '充值'},
            {v: 102, name: '存入'},
            {v: 103, name: '凍結'},
            {v: 104, name: '解凍'},
            {v: 105, name: '存入'},
            {v: 106, name: '存入'},
            {v: 201, name: '提現凍結'},
            {v: 202, name: '提現'},
            {v: 203, name: '提現成功'},
            {v: 204, name: '提現失敗'},
            {v: 205, name: '提現手續費'},
            {v: 206, name: '轉出'},
            {v: 207, name: '轉入'},
            {v: 208, name: '幣幣轉出'},
            {v: 209, name: '幣幣轉入'},
            {v: 301, name: '幣幣手續費'},
            {v: 302, name: '合約收益'},
            {v: 303, name: '合約虧損'},
            {v: 311, name: '購買期權'},
            {v: 312, name: '期權收益'},
            {v: 313, name: '期權退回'},
            {v: 314, name: '期權手續費'},
            {v: 315, name: '幣幣買入凍結'},
            {v: 316, name: '幣幣買入扣除'},
            {v: 317, name: '幣幣買入返回'},
            {v: 318, name: '幣幣買入到賬'},
            {v: 319, name: '幣幣買入到賬'},
            {v: 320, name: '幣幣賣出凍結'},
            {v: 321, name: '幣幣賣出扣除'},
            {v: 322, name: '幣幣賣出返回'},
            {v: 323, name: '幣幣賣出到賬'},
            {v: 324, name: '幣幣賣出到賬'},
            {v: 325, name: '幣幣手續費'},
            {v: 401, name: '礦機加入'},
            {v: 402, name: '礦機返回'},
            {v: 403, name: '礦機收入'},
            {v: 404, name: '礦機退出'},
            {v: 405, name: '礦機退出手續費'},
            {v: 501, name: '盲盒購買'},
            {v: 502, name: '盲盒收入'},
        ],
        withdrawTypes: [
            { v: 0, name: '申請中' },
            { v: 1, name: '處理中' },
            { v: 2, name: '成功' },
            { v: 3, name: '失敗' },
        ],
        types: [
            { v: 4, name: '期權帳戶' },
            { v: 3, name: '法幣帳戶' },
            { v: 2, name: '合約帳戶' },
            { v: 1, name: '幣幣帳戶' },
        ],
        login: {
            aboutUs: "關於我們",
            notice: "為了便捷服務充值請聯系線上客服。",
            login: "登入",
            selectLanguage: "選擇語言",
            placeEmail: "郵箱或手機",
            placePsd: "密碼",
            noAccount: "沒有帳號？ 去",
            register: "注册",
            btnLogin: "登入",
            forget: "忘記密碼?",
        },
        register: {
            register: "注册",
            selectLanguage: "選擇語言",
            placeEmail: "郵箱",
            placePsd: "密碼",
            placeCrmPsd: "確認密碼",
            placeInviteId: "邀請碼",
            haveAccount: "已經有帳戶？ 去",
            login: "登入",
            btnRegister: "注册",
            getCode: "獲取驗證碼",
            code: "驗證碼",
            psd_error: "請再次輸入密碼",
            psd_error2: "兩次密碼不一致",
            email_error: "郵箱不能為空！",
            email_error2: "郵箱格式不正確！",
            elail_register: "郵箱註冊",
            phone_register: "手機註冊",
            country: "國家",
            placePhone: "手機",
            countryCode: "國家代碼",
            pleaseEnter: "請輸入"
        },
        forget: {
            title: "忘記密碼",
            labelEmail: "電子郵件",
            labelEmailCode: "電子郵件驗證碼",
            placeEmailCode: "電子郵件驗證碼",
        },
        forgetPay: {
            title: "忘記交易密碼",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "彩票記錄",
        },
        Blind_box_voucher: {
            title: "代金券",
            voucher: "代金券",
            currentVoucher: "當前憑證",
        },
        Blind_box: {
            title: "盲盒",
            goodluck: "祝你好運",
            panelTit: "請選擇下麵的盲盒",
            tip1: "1次抽獎必須贏得2500張優惠券",
            tip2: "你還有2500次才能拿到",
            limit1: "今天剩餘的繪圖",
            limit2: "10次",
            times: "次",
            draw: "畫",
            discount: "折扣",
            payable: "應付的",
            blind_box: "盲盒",
            available: "可獲得的",
            lotteryRecord: "彩票記錄",
            Determine_blind_box: "確定盲盒",
            tip3: "2.一次彩票抽獎每天限10次，連續抽獎不限5次",
            tip4: "3.兌換優惠券可以在EXCHANG兌換BTC和ETH",
            tip5: "4. 每一次彩票都會中獎",
            tip6: "5.超過10000次的5次USDT提款必須獲得“兌換券”，超過5000次的10次USDT取款必須獲得“可兌換券”，2500次以上的20次USDT抽獎必須獲得“互換券”",
            tip7: "5美元彩票獎金和概率：",
            tip8: "1. 15~200 OTD，概率50%",
            tip9: "2. 1~5个EOS，概率為10%",
            tip10: "3. 5~30XRP，概率10%",
            tip11: "4. 1~10 USDT，概率為10%",
            tip12: "5. 10~50DOGE，概率10%",
            tip13: "6. 3 ETH，概率為5%",
            tip14: "7. 10 ETH，概率2%",
            tip15: "8. 20ETH，概率為2%",
            tip16: "9. 2 BTC，概率1%",
            tip17: "10 USDT抽獎獎金和概率：",
            tip18: "1. 30~600 OTD，概率50%",
            tip19: "2. 1~20EOS，概率為10%",
            tip20: "3. 10~80XRP，概率為10%",
            tip21: "4. 5~30美元/噸，概率10%",
            tip22: "5. 20~100DOGE，概率10%",
            tip23: "6. 20 ETH，概率4%",
            tip24: "7. 1 BTC，概率4%",
            tip25: "8. 2 BTC，概率2%",
            tip26: "20 USDT抽獎獎金和概率：",
            tip27: "1. 5~50USDT，概率30%",
            tip28: "2. 1~50 ADA，概率30%",
            tip29: "3. 1~20EOS，概率為10%",
            tip30: "4. 3DOT，概率為5%",
            tip31: "5. 1个XMR，概率為5%",
            tip32: "6. 1 BCH，概率為5%",
            tip33: "7. 1 ETH，概率為5%",
            tip34: "8. 10000 DOGE，概率為5%",
            tip35: "9. 2 BTC，3%概率",
            tip36: "10. 5 BTC，概率2%",
            tip37: "幸運抽獎管道：:",
            tip38: "1. 5 USDT抽1次，10 USDT抽2次，20 USDT抽一次，連續抽5次可享受5%的折扣",
        },
    },
    Financial: {
        index: {
            finance: "金融",
            ieo: "IEO",
            LockMining: "鎖倉挖礦",
            day: "天",
            unit: "起",
            Daily_yield: "起",
        },
    },
    Follow: {
        index: {
            title: "交易員清單",
            tip: "數據每小時更新一次",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "財務記錄",
            number: "數量",
            time: "時間",
        },
        Assets_record: {
            title: "帳戶詳情",
            total_assets: "總資產折合",
            Available_Credit: "可用額度",
            locking: "鎖定",
            Financial_records: "財務記錄",
            number: "數量",
            time: "時間",
        },
        Assets: {
            Account_record: "帳變記錄",
            Recharge: "充值",
            Collection: "收款",
            Withdrawal: "提現",
            transfer: "轉帳",
            Currency_transfer: "幣幣劃轉",
            Coins: "幣幣",
            contract: "合約",
            Legal_currency: "法幣",
            option: "期權",
            Equivalent: "折合",
        },
        FundTransfer: {
            title: "資金劃轉",
            Select_Currency: "選擇幣種",
            from: "從",
            Transfer_quantity: "劃轉數量",
            to: "至",
        },
        Receive_withdraw_record: {
            title: "記錄",
        },
        Receive: {
            title: "充值",
            Digital_currency: "數位貨幣",
            tip: "聯系客服索要充值地址",
            next: "下一步",
            Recharge_address: "充值地址",
            Recharge_number: "充值數量",
            place_Recharge_number: "請輸入充值數量",
            Payment_voucher: "支付憑證",
            place_Payment_voucher: "請輸入支付憑證",
        },
        Transfer: {
            Currency_transfer: "幣幣劃轉",
            Capital_transfer: "資金劃轉",
            from: "從",
            Transfer_number: "劃轉數量",
            need_service_charge: "另需手續費",
            to: "至",
            will_get: "你將得到",
            Available_Credit: "可用額度",
            Exchange_rate: "兌換匯率",
            Service_Charge: "手續費",
        },
        Withdraw: {
            title: "提現",
            Withdrawal: "提現",
            bank_card: "銀行卡",
            wallet_address: "錢包地址",
            Withdrawal_number: "提現數量",
            available: "可用",
            place_Withdrawal_number: "請輸入提現數量",
            all: "全部",
            Minimum_Withdrawal_number: "最低提現數量",
            password: "帳戶密碼",
            Service_Charge: "手續費",
        }
    },
    Guess: {
        title: "彩票",
        min: "最小",
        Bullish: "漲",
        Bearish: "跌",
        Guess_amount: "猜測金額",
        place_Guess_amount: "請輸入金額",
        Current_selection: "當前選擇",
        Payable: "應付的",
        Guess_now: "現在猜猜",
        Available: "可獲得的",
        Lottery_record: "彩票記錄",
        Betting_record: "投注記錄",
        Start_price: "起始價格",
        End_price: "最終價格",
        Game_introduction: "遊戲介紹",
        tip1: "1.測驗分為三個時段：10分鐘、3分鐘和1分鐘。 BTC的上漲和下跌。 BTC的價格是Coinbase+Binance+Huobi三者聯動的平均價格，這真是一部完全公平的小說。",
        tip2: "2.這裡以10分鐘為例：每期前10分鐘為下單時間，後2分鐘為競價時間，後1分鐘為公佈競價價格，競價>競價價格為'漲價'。， 競價價格的猜測價格是'下降'，例如：00:00~00:15，這裡是當天開始的第一個時段，在00:00你下了一個BTC'看漲'的訂單，訂單金額是1000 USDT。 當時間到達00:13時，BTC的價格是35000 USDT，那麼你的標記價格是：35000 USDT，而在00:14公佈的結果價格是35001 USDT，那麼猜測結果是'上升'，你猜對了，是'看漲'。 恭喜你的猜測。 你將獲得950美元的利潤。 系統將為您結算1950美圓T，其中50美圓T為平臺，您獲利950美圓T。",
        tip3: "3.賠率是1:0.95，為什麼不是1:1？ 因為平臺需要收取5%的手續費。",
        tip4: "4.測驗結束後，將對結果進行結算。 你贏的錢是由輸家給你的。 為了保證平臺不參與用戶之間的賭博行為，如果贏家多，輸家少，您需要等待輸家出現後才能交付獎金。 例如：當你投注100000 USDT並猜測你贏了，你應該得到95000 USDT。 然而，只有80000 USDT是輸的，而交付池資金不足以支付你的95000 USDT。 你需要等待交割池顯示輸家的資金。 一旦你達到15000 USDT，平臺將結算你的95000獎金。",
        tip5: "5.如果你實在等不及想快速拿走獎金，你可以進行快速交割，但是快速交割只能收到95000USDT的25%，也就是說你只能拿走2375USDT，因為快速交割相當於你的一般獎金。 權力轉給了平臺，那麼平臺就需要承擔相對的風險，所以平臺需要扣除巨額費用。",
        tip6: "本問答不涉及平臺與用戶之間的對賭，如果你發現這樣的情况，可以立即舉報.",
    },
    home: {
        title: "",
        Total_assets: "總資產折合",
        Recharge: "充值",
        documentary: "跟單",
        transaction: "交易",
        IEO: "IEO",
        Lock_mining: "鎖倉挖礦",
        Investment_Financing: "投資理財",
        Currency_transfer: "幣幣劃轉",
        Currency_swap: "閃兌",
        Withdrawal: "提現",
        Blind_box: "盲盒",
        Guess: "猜測",
        Daily_tasks: "日常任務",
        Sign_task: "簽署任務",
        Buy_coins_quickly: "快捷買幣",
        Buy_quickly: "快速 買",
        day: "天",
        rise: "起",
        Daily_yield: "日收益率",
        quotation: "行情",
        Transaction_pair: "交易對",
        Latest_price: "最新價",
        Rise_fall: "今日漲跌幅",
    },
    ieo: {
        ieo: "IEO認購",
        Lock_up_cycle: "鎖倉週期",
        day: "天",
        Closed: "已結束",
        Completed: "已完成",
        Subscribed: "已認購",
        surplus: "剩餘",
        My_subscription: "我的認購",
        Subscription_time: "申購時間",
        currency: "幣種",
        Requested_number: "申請數量",
        Pass_number: "通過數量",
        Market_time: "上市時間",
    },
    Invest: {
        conduct: "理财",
        Special_area: "專區",
        Annualized_rate: "年化收益率",
        Linked_price: "掛鉤參考價",
        Remaining_share: "剩餘份額",
        Position_duration: "持倉期限",
        due_date: "到期日",
        My_conduct: "我的理财",
        all: "全部",
        fail: "失敗",
        Purchased: "已買入",
        settlement: "結算中",
        Completed: "已完成",
    },
    Lockming: {
        Minimum_single: "單筆最少",
        Daily_yield: "日收益率",
        Lock_up_cycle: "鎖倉週期",
        payout_time: "派息時間",
        everyday: "每日",
        Custody_funds: "託管資金",
        Return_expiration: "到期返回",
        called_away: "提前贖回",
        placeholder: "請輸入內容",
        all: "全部",
        Estimated_income: "預計收益",
        Available_assets: "可用資產",
        subscription: "認購",
        Lock_mining: "鎖倉挖礦",
        Funds_custody: "正在託管的資金",
        Estimated_yield_today: "預計今日收益率",
        Accumulated_income: "累計收益",
        Orders_custody: "託管中的訂單",
        Delegated_Order: "委託訂單",
        Lock: "鎖倉",
        day: "天",
        Lock_list: "鎖倉清單",
        Exited: "已退出",
        Redeemed: "已贖回",
        have_hand: "進行中",
        redeem: "贖回",
        Amount_currency: "存幣數量",
        Lockup_time: "鎖倉時間",
        Expiration_time: "到期時間",
        early_redemption: "提前贖回違約金",
    },
    Market: {
        Optional: "自選",
        Transaction_pair: "交易對",
        Latest_price: "最新價",
        place_search: "蒐索您關心的幣種/股票名稱/程式碼",
    },
    Setting: {
        label_currency: "選擇幣種",
        place_currency: "請選擇幣種",
        label_walletTitle: "錢包名稱",
        place_walletTitle: "請輸入錢包名稱",
        label_walletAddress: "錢包地址",
        place_walletAddress: "請輸入錢包地址",
        Add_bankCard: "添加銀行卡",
        label_name: "真實姓名",
        label_depositBank: "開戶行",
        label_bankCard: "銀行卡號",
        label_beneficary_address: "受益人地址",
        label_payee_name: "收款人名稱",
        label_bank_name: "銀行名稱",
        label_swift_code: "銀行程式碼",
        APP_download: "MSB 證書",
        label_currentPassword: "當前密碼",
        place_currentPassword: "請輸入當前密碼",
        label_newPassword: "新登入密碼",
        place_newPassword: "請輸入新登入密碼",
        label_confirmationPassword: "確認新密碼",
        place_confirmationPassword: "請輸入確認新密碼",
        label_newPassword1: "交易密碼",
        place_newPassword1: "請輸入交易密碼",
        payment_notice: "注意：交易密碼的初始預設值是當前的登入密碼",
        Real_mark1: "個人身份認證",
        Real_mark2: "完成認證後可獲得的許可權：",
        Real_mark3: "Lv1.基礎認證",
        Real_mark4: "認證後可以體現，24小時限額200BTC",
        Real_mark5: "認證後可以進行法幣交易，單筆限額2000USDT",
        Real_mark6: "Lv2.高級認證",
        Real_mark7: "新增提現額度，24小時限額500BTC",
        Real_mark8: "新增法幣交易額度，單筆限額1000000USDT",
        Real_mark9: "溫馨提示：為保護用戶資金安全，請先完成郵箱和電話的綁定，否則您的認證將無法通過。",
        Real_mark10: "基礎認證",
        Real_mark11: "已認證",
        Real_mark12: "高級認證",
        Real_mark13: "去認證",
        label_realName: "姓名",
        message_realName: "請輸入真實姓名",
        label_cardNumber: "身份證號",
        message_cardNumber: "請輸入身份證號",
        upload_front: "上傳身份證正面",
        upload_back: "上傳身份證背面",
        upload_people: "上傳手持身份證",
        under_review: "稽核中",
        security_setting: "安全設置",
        my_bank: "我的銀行卡",
        Change_Loginpsd: "修改登入密碼",
        Change_transactionpsd: "修改交易密碼",
        email: "郵箱",
        my: "我的",
        Bank: "銀行卡",
        AddBank: "添加銀行卡",
        walletAddress: "錢包地址",
        Default_currency: "默認法幣",
        language: "語言",
        share: "分享",
        logout: "退出",
        qrcode: "我的 Qrcode",
        personal: "個人資訊",
        identity: "身份認證",
        nickname: "昵稱",
        nickname_tip: "2-10個字元，支持中英文、數位",
        Add_newAccount: "添加新帳號",
        label_user: "用戶名稱",
        label_number: "銀行卡號",
        label_branch: "銀行地址",
        label_bank: "銀行名稱",
        label_swift: "Swift編碼",
        label_address: "用戶地址",
    },
    Task: {
        daily_tasks: "日常任務",
        activity_today: "今天的活動",
        task_list: "任務清單",
        weekly_tasks: "每週任務",
        activity: "活動",
        undone: "未完成的",
        receive: "接收",
        task_rules: "任務規則",
        task_rules1: "1：活動在一周內累積疊加，每週一淩晨0點自動清零重新結算",
        task_rules2: "2：每天完成所有4項日常任務，達到100個活動級別後獲得5個CCA硬幣；",
        task_rules3: "3：當活動達到350時，您可以獲得10個CCA硬幣",
        task_rules4: "4：當活動達到700時，您可以獲得N1礦機1天的體驗",
        sign_task: "登入任務",
        Sign_everyDay: "每天登入",
        CCA_coin: "CCA硬幣",
        signIn: "登入",
        day: "天",
        daily_checkIn: "每日登記入住",
        put_away: "收起",
        Expand: "擴大",
        signIn_now: "立即登入",
        checkIn_rules: "值機規則",
        checkIn_rules1: "1：連續簽到5天將獲得5枚CCA硬幣",
        checkIn_rules2: "2：連續簽到14天將獲得20枚CCA硬幣",
        checkIn_rules3: "3：連續21天簽到，獎勵N1礦機三天體驗時間",
        checkIn_rules4: "4：連續24天入住後，保持入住記錄不間斷，每天入住可獲得5個CCA硬幣",
    },
    Transaction: {
        delegate_List: "委託清單",
        risk_rate: "風險率",
        total_income: "持倉總收益",
        oneClick_closing: "一鍵平倉",
        optional: "自選",
        all_closed: "全部平倉",
        flat_multiple: "只平多單",
        flat_empty: "只平空單",
        purchase: "買入",
        sellOut: "賣出",
        market_price: "市價",
        fixed_price: "限價",
        trading_hands: "交易手數",
        multiple: "倍數",
        bond: "保證金",
        Service_Charge: "手續費",
        balance: "餘額",
        long: "做多",
        Short: "做空",
        Price: "價格",
        number: "數量",
        type: "類型",
        time: "時間",
        operation: "操作",
        Transaction_pair: "交易對",
        direction: "方向",
        search: "蒐索",
        Latest_price: "最新價",
        Rise_fall: "今日漲跌幅",
        entrust: "委託",
        all: "全部",
        entrust_time: "委託時間",
        state: "狀態",
        Completed: "已完成",
        air_completed: "完成",
        place_amount: "請輸入內容",
        turnover: "交易額",
        contract: "合約",
        option: "期權",
        coins: "幣幣",
        High_today: "今日高",
        Low_today: "今日低",
        Entrusted_bill: "委託掛單",
        Buy_more: "買多",
        Buy_less: "買少",
        buy: "買",
        sell: "賣",
        crm_order: "訂單確認",
        Current_price: "當前價",
        expiration_time: "選擇到期時間",
        Option_position: "期權持倉",
        position: "持倉",
        history: "歷史",
        Purchase_price: "購買價",
        Purchase_amount: "購買金額",
        Order_duration: "訂單時長",
        Selling_time: "賣出時間",
        Profit_loss: "盈虧",
        Hand_count: "手數",
        countdown: "倒數計時",
        order_number: "訂單號",
        currency: "幣種",
        reales_Number: "實際成交",
        total_price: "總價",
        close_price: "收盤價",
        volume_trade: "貿易量",
        hand_fee: "手續費",
        continue_order: "繼續下訂單",
    },
    footer: {
        home: "首頁",
        market: "行情",
        transaction: "交易",
        financial: "金融",
        fund: "資產",
        rule: "規則",
        select_language: "請選擇語言"
    },
    common: {
        noData: "暫無相關資料",
        Exchange: "交換",
        Cancel: "取消",
        Confirm: "確認",
        Submit: "提交",
        more: "更多",
        add: "添加",
        Cover: "隱蔽",
        Success: "成功",
        portion: "份",
        day: "天",
        please_select: "請選擇",
        no_data: "無數據",
    },
    script: {
        error_msg: "數位/字母",
        register_noAccount: "請輸入用戶電話或用戶電子郵件!",
        send_phone_scuccess: "發送短信成功!",
        register_success: "注册成功！",
        please_enter_amount: "請輸入金額！",
        please_enter_money: "請輸入購買資金！",
        success: "成功!",
        bankId_msg: "請選擇錢包",
        amount_msg: "請輸入金額",
        password_msg: "請輸入密碼",
        redemption_msg: "確認提前贖回嗎?",
        tip: "提示",
        redemption_success: "贖回成功!",
        canceled: "已取消",
        realname_submit_success: "真實姓名提交成功!",
        logout_tip: "你確定要註銷嗎？",
        login_tip: "你確定你要登入嗎？",
        signIn: "登入",
        closeout_tip: "確定全部平倉?",
        closeout_success: "全部平倉成功!",
        cancel_tip: "你確定要取消嗎?",
        notice: "通知",
        insufficient_balance: "餘額不足!",
    },
};
