export default {
    account: {
        more: {
            service: "primeros auxilios",
            moreMin: "No debe ser inferior al importe mínimo de retirada",
            placePrefix: "Por favor elija el número de país",
        },
        aboutUs: {
            lint1: "At IDEX, we look at investing differently, Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing,",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality,",
            lint3: "IDEX is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products,",
            lint4: "IDEX strictly abides by the laws and regulations of various regions, We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets, ",
            lint5: "IDEX is one of the fastest growing fintech companies, We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market,",
        },
        billTypes: [
            {v: 101, name: 'recargar'},
            {v: 102, name: 'Depósito'},
            {v: 103, name: 'Congelar'},
            {v: 104, name: 'Descongelar'},
            {v: 105, name: 'Depósito'},
            {v: 106, name: 'Depósito'},
            {v: 201, name: 'Retirada congelada'},
            {v: 202, name: 'Retirada'},
            {v: 203, name: 'Retirada efectuada'},
            {v: 204, name: 'Retirada fallida'},
            {v: 205, name: 'Tasa de retirada'},
            {v: 206, name: 'Transfer out'},
            {v: 207, name: 'Transferencia entrante'},
            {v: 208, name: 'Transferencia salida'},
            {v: 209, name: 'Transferencia de monedas'},
            {v: 301, name: 'Comisión por monedas'},
            {v: 302, name: 'Ingresos por contrato'},
            {v: 303, name: 'Pérdidas por contrato'},
            {v: 311, nombre: 'Opción de compra'},
            {v: 312, name: 'Ganancia opción'},
            {v: 313, name: 'Retorno de opción'},
            {v: 314, nombre: 'Comisión de opción'},
            {v: 315, nombre: 'Congelación compra monedas'},
            {v: 316, nombre: 'Coin buy deduction'},
            {v: 317, name: 'Devolución compra monedas'},
            {v: 318, name: 'Coin buy-in received'},
            {v: 319, name: 'Coin buy returned'},
            {v: 320, name: 'Coin sell freeze'},
            {v: 321, name: 'Monedas vendidas descontadas'},
            {v: 322, name: 'Devolución venta monedas'},
            {v: 323, name: 'Monedas vendidas a cuenta'},
            {v: 324, name: 'Devolución monedas vendidas'},
            {v: 325, name: 'Comisión monedas'},
            {v: 401, name: 'Minero unirse'},
            {v: 402, name: 'Miner return'},
            {v: 403, name: 'Ingreso minero'},
            {v: 404, name: 'Salida minero'},
            {v: 405, name: 'Tasa de salida del minero'},
            {v: 501, name: 'Compras en caja ciega'},
            {v: 502, name: 'Ingresos caja ciega'},
        ],
        withdrawTypes: [
            {v: 0, nombre: 'en solicitud'},
            {v: 1, nombre: 'en proceso'},
            {v: 2, nombre: 'Éxito'},
            {v: 3, nombre: 'fallido'},
        ],
        types: [
            { v: 4, name: 'Cuentas Opciones' },
            { v: 3, name: 'Cuentas en divisas' },
            { v: 2, name: 'Cuenta de Contratos' },
            { v: 1, name: 'Cuentas de divisas' },
        ],
        login: {
            aboutUs: "Quiénes somos",
            notice: "Para recargar fácilmente el servicio, póngase en contacto con el servicio de atención al cliente en línea,",
            login: "Login",
            selectLanguage: "Select Language",
            placeEmail: "Correo electrónico o móvil",
            placePsd: "Contraseña",
            noAccount: "¿No tienes cuenta? a",
            register: "Registrarse",
            btnLogin: "Login",
            forget: "Olvidar?",
        },
        register: {
            register: "Registrarse",
            selectLanguage: "Select Language",
            placeEmail: "buzón",
            placePsd: "Contraseña",
            placeCrmPsd: "La confirmación de la contraseña",
            placeInviteId: "A código",
            haveAccount: "¿Ya tienes una cuenta? a",
            login: "Login",
            btnRegister: "Registrarse",
            getCode: "Obtener el Código de verificación",
            code: "Código de verificación",
            psd_error: "Vuelva a introducir su contraseña",
            psd_error2: "Dos contraseñas incoherentes",
            email_error: "El buzón no puede estar vacío,",
            email_error2: "Formato de correo electrónico incorrecto",
            elail_register: "Correo electrónico",
            phone_register: "Teléfono",
            country: "País",
            placePhone: "Teléfono",
            countryCode: "Código de país / región",
            pleaseEnter: "Por favor, introduzca"
        },
        forget: {
            title: "Olvida la contraseña",
            labelEmail: "Correo electrónico",
            labelEmailCode: "Código de verificación de correo electrónico",
            placeEmailCode: "Código de verificación de correo electrónico",
        },
        forgetPay: {
            title: "Olvidar la contraseña de la transacción",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "registros de lotería",
        },
        Blind_box_voucher: {
            title: "vale",
            voucher: "vale",
            currentVoucher: "credenciales actuales",
        },
        Blind_box: {
            title: "caja ciega",
            goodluck: "buena suerte",
            panelTit: "Seleccione un cuadro ciego a continuación",
            tip1: "1 sorteo debe ganar 2500 cupones",
            tip2: "Tienes 2500 más para conseguir",
            limit1: "dibujo restante de hoy",
            limit2: "10 veces",
            times: "veces",
            draw: "pintura",
            discount: "Descuento",
            payable: "pagadero",
            blind_box: "caja ciega",
            available: "disponible",
            lotteryRecord: "registros de lotería",
            Determine_blind_box: "Determinar la caja ciega",
            tip3: "2, El sorteo de lotería se limita a 10 veces al día y el sorteo continuo no se limita a 5 veces,",
            tip4: "3, El canje de cupones se puede canjear por BTC y ETH en exchang",
            tip5: "4, Cada lotería gana el Premio",
            tip6: "5, Cinco retiros de usdt de más de 10000 veces deben obtener 'cupones de intercambio', 10 retiros de usdt de más de 5000 veces deben obtener 'cupones de intercambio', y 20 sorteos de usdt de más de 2500 veces deben obtener 'cupones de intercambio',",
            tip7: "5 dólares en premios de lotería y probabilidad:",
            tip8: "1, 15 a 200 otd, 50% de probabilidad",
            tip9: "2, 1 a 5 eos, con una probabilidad del 10%",
            tip10: "3, 5 a 30xrp, probabilidad 10%",
            tip11: "4, 1 a 10 usdt, probabilidad 10%",
            tip12: "5, 10 a 50 doge, probabilidad 10%",
            tip13: "6, 3 eth, con una probabilidad del 5%",
            tip14: "7, 10 eth, probabilidad 2%",
            tip15: "8, 20eth, con una probabilidad del 2%",
            tip16: "9, 2 btc, probabilidad 1%",
            tip17: "10 Premios y probabilidad del sorteo usdt:",
            tip18: "1, 30 a 600 otd, 50% de probabilidad",
            tip19: "2, 1 a 20 eos, con una probabilidad del 10%",
            tip20: "3, 10 a 80 xrp, probabilidad del 10%",
            tip21: "4, 5 a 30 dólares / tonelada, probabilidad 10%",
            tip22: "5, 20 a 100 doge, probabilidad 10%",
            tip23: "6, 20 eth, probabilidad 4%",
            tip24: "7, 1 btc, probabilidad 4%",
            tip25: "8, 2 btc, probabilidad 2%",
            tip26: "20 Premios y probabilidad del sorteo usdt:",
            tip27: "1, 5 a 50 usdt, 30% de probabilidad",
            tip28: "2, 1 a 50 ada, 30% de probabilidad",
            tip29: "3, 1 a 20 eos, con una probabilidad del 10%",
            tip30: "4, 3dot, con una probabilidad del 5%",
            tip31: "5, 1 xmr con una probabilidad del 5%",
            tip32: "6, 1 bch, con una probabilidad del 5%",
            tip33: "7, 1 eth, con una probabilidad del 5%",
            tip34: "8, 10000 doge, con una probabilidad del 5%",
            tip35: "9, 2 btc, 3% de probabilidad",
            tip36: "10, 5 btc, probabilidad 2%",
            tip37: "Método de sorteo de la suerte:",
            tip38: "1, 5 usdt 1 sorteo, 10 usdt 2 sorteos, 20 usdt 1 sorteo, 5 sorteos consecutivos pueden disfrutar de un descuento del 5%",
        },
    },
    Financial: {
        index: {
            finance: "Finanzas",
            ieo: "IEO",
            LockMining: "minería de bloqueo",
            day: "cielo",
            unit: "subir",
            Daily_yield: "subir",
        },
    },
    Follow: {
        index: {
            title: "lista de comerciantes",
            tip: "Los datos se actualizan cada hora",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "registros financieros",
            number: "cantidad",
            time: "hora",
        },
        Assets_record: {
            title: "detalles de la cuenta",
            total_assets: "Activos totales equivalentes",
            Available_Credit: "Monto disponible",
            locking: "cierre",
            Financial_records: "registros financieros",
            number: "cantidad",
            time: "hora",
        },
        Assets: {
            Account_record: "Registro de cambio de cuenta",
            Recharge: "recargar",
            Collection: "recolectar dinero",
            Withdrawal: "retirar",
            transfer: "transferir",
            Currency_transfer: "transferencia de moneda",
            Coins: "Moneda",
            contract: "contrato",
            Legal_currency: "moneda francesa",
            option: "opciones",
            Equivalent: "convertir",
        },
        FundTransfer: {
            title: "transferencia de fondos",
            Select_Currency: "Seleccione el tipo de moneda",
            from: "De",
            Transfer_quantity: "monto de la transferencia",
            to: "a",
        },
        Receive_withdraw_record: {
            title: "Registro",
        },
        Receive: {
            title: "recargar",
            Digital_currency: "moneda digital",
            tip: "Póngase en contacto con el servicio de atención al cliente para solicitar la dirección de recarga",
            next: "Próximo paso",
            Recharge_address: "Dirección de recarga",
            Recharge_number: "Monto de recarga",
            place_Recharge_number: "Por favor ingrese el monto de la recarga",
            Payment_voucher: "comprobante de pago",
            place_Payment_voucher: "Por favor ingrese comprobante de pago",
        },
        Transfer: {
            Currency_transfer: "transferencia de moneda",
            Capital_transfer: "transferencia de fondos",
            from: "De",
            Transfer_number: "monto de la transferencia",
            need_service_charge: "tarifa de manejo adicional",
            to: "a",
            will_get: "conseguirás",
            Available_Credit: "Monto disponible",
            Exchange_rate: "tipo de cambio",
            Service_Charge: "tasa de tramitación",
        },
        Withdraw: {
            title: "transferir",
            Withdrawal: "retirar",
            bank_card: "tarjeta bancaria",
            wallet_address: "Menciona la dirección de la billetera",
            Withdrawal_number: "Cantidad de retiro",
            available: "disponible",
            place_Withdrawal_number: "Por favor ingrese el monto del retiro",
            all: "todas",
            Minimum_Withdrawal_number: "Cantidad mínima de retiro",
            password: "contraseña de la cuenta",
            Service_Charge: "tasa de tramitación",
        }
    },
    Guess: {
        title: "lotería",
        min: "el mas pequeño",
        Bullish: "subir",
        Bearish: "caer",
        Guess_amount: "Adivina la cantidad",
        place_Guess_amount: "Por favor ingrese la cantidad",
        Current_selection: "selección actual",
        Payable: "pagadero",
        Guess_now: "adivina ahora",
        Available: "disponible",
        Lottery_record: "registros de lotería",
        Betting_record: "récord de apuestas",
        Start_price: "Precio inicial",
        End_price: "precio final",
        Game_introduction: "introducción del juego",
        tip1: "1, el cuestionario se divide en tres periodos: 10 min, 3 min y 1 min, la subida y bajada del BTC, el precio del BTC es el precio medio de Coinbase+Binance+Huobi enlazados, esta es una novela completamente justa" ,
        tip2: "2, He aquí un ejemplo de 10 minutos: los primeros 10 minutos de cada periodo son para hacer pedidos, los segundos 2 minutos son para pujar, el último 1 minuto es para anunciar el precio de la puja, y la puja > precio de la puja es 'al alza', Por ejemplo: 00:00~00:15, aquí está el primer período al principio del día, a las 00:00 usted coloca una orden de 'compra' de BTC por 1000 USDT, cuando el tiempo llega a las 00:13, el precio de BTC es 35000 USDT, entonces su precio marcado es: 35000 USDT, Y a las 00:14 el precio de resultado publicado es 35001 USDT, por lo que la conjetura es 'alcista' y has acertado, es 'alcista', Enhorabuena por tu acierto, Usted recibirá un beneficio de 950 USDT, El sistema le liquidará 1950 USDT, de los cuales 50 USDT son de la plataforma y usted obtendrá un beneficio de 950 USDT" ,
        tip3: "3, Las probabilidades son 1:0,95, ¿por qué no 1:1? Porque la plataforma cobra una comisión del 5%" ,
        tip4: "4, Los resultados se resolverán al final del concurso, El dinero que ganes te lo dará el perdedor, Para que la plataforma no participe en apuestas entre usuarios, si hay muchos ganadores y pocos perdedores, tendrás que esperar a que aparezcan los perdedores para entregarles tus ganancias, Por ejemplo, cuando apuestas 100,000 USDT y adivinas que ganas, deberías recibir 95,000 USDT, sin embargo, sólo se pierden 80,000 USDT y el fondo de entrega no tiene suficiente dinero para pagarte 95,000 USDT, tienes que esperar a que el fondo de entrega muestre el dinero de los perdedores, Una vez que alcances los 15,000 USDT, la plataforma liquidará tu bonificación de 95,000" ,
        tip5: "5, Si realmente no puedes esperar y quieres llevarte tu bonificación rápidamente, puedes hacer una entrega rápida, pero la entrega rápida sólo recibirá el 25% de los 95000 USDT, lo que significa que sólo podrás llevarte 2375 USDT, ya que la entrega rápida equivale a tu bonificación general, El poder se transfiere a la plataforma, y entonces la plataforma tiene que asumir el riesgo relativo, por lo que la plataforma tiene que deducir una enorme comisión," ,
        tip6: "Este Q&A no es sobre apuestas entre plataformas y usuarios, si encuentras una situación así, puedes reportarla inmediatamente," ,
    },
    home: {
        title: "",
        Total_assets: "Activos totales equivalentes",
        Recharge: "recargar",
        documentary: "documental",
        transaction: "comercio",
        IEO: "IEO",
        Lock_mining: "minería de bloqueo",
        Investment_Financing: "Inversiones y gestión financiera",
        Currency_transfer: "transferencia de moneda",
        Currency_swap: "Nhấp nháy",
        Withdrawal: "retirar",
        Blind_box: "caja ciega",
        Guess: "adivinar",
        Daily_tasks: "tareas diarias",
        Sign_task: "firmar tarea",
        Buy_coins_quickly: "Compra monedas rápidamente",
        Buy_quickly: "compra rapida",
        day: "cielo",
        rise: "subir",
        Daily_yield: "Tasa de rendimiento diaria",
        quotation: "cotizaciones",
        Transaction_pair: "par comercial",
        Latest_price: "Último precio",
        Rise_fall: "El ascenso y la caída de hoy",
    },
    ieo: {
        ieo: "Suscripción a la OEI",
        Lock_up_cycle: "Período de bloqueo",
        day: "cielo",
        Closed: "sobre",
        Completed: "terminado",
        Subscribed: "Suscrito",
        surplus: "restante",
        My_subscription: "mi suscripción",
        Subscription_time: "tiempo de suscripción",
        currency: "Moneda",
        Requested_number: "Número de aplicaciones",
        Pass_number: "cantidad de pases",
        Market_time: "hora de comprar",
    },
    Invest: {
        conduct: "gestión financiera",
        Special_area: "área especial",
        Annualized_rate: "Tasa de rendimiento anualizada",
        Linked_price: "precio de referencia fijo",
        Remaining_share: "parte restante",
        Position_duration: "Período de tenencia",
        due_date: "fecha de caducidad",
        My_conduct: "mis finanzas",
        all: "todas",
        fail: "fallar",
        Purchased: "compró",
        settlement: "Asentamiento",
        Completed: "terminado",
    },
    Lockming: {
        Minimum_single: "mínimo único",
        Daily_yield: "Tasa de rendimiento diaria",
        Lock_up_cycle: "Período de bloqueo",
        payout_time: "Tiempo de dividendos",
        everyday: "diariamente",
        Custody_funds: "fondos de depósito en garantía",
        Return_expiration: "devolución vencida",
        called_away: "Redención temprana",
        placeholder: "Por favor ingrese el contenido",
        all: "todas",
        Estimated_income: "Ingresos estimados",
        Available_assets: "activos disponibles",
        subscription: "suscribir",
        Lock_mining: "minería de bloqueo",
        Funds_custody: "Fondos en custodia",
        Estimated_yield_today: "Rendimiento estimado hoy",
        Accumulated_income: "Ingreso acumulado",
        Orders_custody: "Pedidos en depósito",
        Delegated_Order: "orden comisionada",
        Lock: "Encerrar",
        day: "cielo",
        Lock_list: "Lista de bloqueo",
        Exited: "abandonar",
        Redeemed: "redimido",
        have_hand: "Procesando",
        redeem: "redención",
        Amount_currency: "cantidad del depósito",
        Lockup_time: "tiempo de bloqueo",
        Expiration_time: "Fecha de caducidad",
        early_redemption: "Redención anticipada de daños liquidados",
    },
    Market: {
        Optional: "Opcional",
        Transaction_pair: "par comercial",
        Latest_price: "Último precio",
        place_search: "Busque la moneda/el nombre de la acción/el código que le interese",
    },
    Setting: {
        label_currency: "Seleccione el tipo de moneda",
        place_currency: "Seleccione la moneda",
        label_walletTitle: "nombre de la billetera",
        place_walletTitle: "Por favor ingrese un nombre de billetera",
        label_walletAddress: "dirección de la billetera",
        place_walletAddress: "Por favor ingrese la dirección de la billetera",
        Add_bankCard: "añadir tarjeta bancaria",
        label_name: "nombre real",
        label_depositBank: "banco",
        label_bankCard: "número de tarjeta bancaria",
        label_beneficary_address: "Dirección del beneficiario",
        label_payee_name: "Nombre del beneficiario",
        label_bank_name: "Nombre del Banco",
        label_swift_code: "Código bancario",
        APP_download: "Certificación msb",
        label_currentPassword: "contraseña actual",
        place_currentPassword: "Por favor ingrese la contraseña actual",
        label_newPassword: "Nueva contraseña de inicio de sesión",
        place_newPassword: "Por favor, introduzca una nueva contraseña de inicio de sesión",
        label_confirmationPassword: "Confirmar la nueva contraseña",
        place_confirmationPassword: "Por favor ingrese para confirmar la nueva contraseña",
        label_newPassword1: "Contraseña de transacción",
        place_newPassword1: "Introduzca la contraseña de la transacción",
        payment_notice: "Nota: El valor inicial por defecto de la contraseña de la transacción es la contraseña de acceso actual",
        Real_mark1: "identificación personal",
        Real_mark2: "Permisos disponibles después de la certificación:",
        Real_mark3: "Lv1,Certificación Básica",
        Real_mark4: "Se puede reflejar después de la certificación, límite de 24 horas 200BTC",
        Real_mark5: "Después de la autenticación, se pueden realizar transacciones en moneda legal, con un límite de transacción única de 2000USDT",
        Real_mark6: "Lv2,Certificación Avanzada",
        Real_mark7: "Aumente la cantidad reflejada, el límite de 24 horas es 500BTC",
        Real_mark8: "Aumentar el límite de transacciones en moneda fiduciaria, un límite único de 1,000,000 USDT",
        Real_mark9: "Recordatorio: para proteger la seguridad de los fondos de los usuarios, primero complete el enlace del correo electrónico y el teléfono; de lo contrario, su autenticación fallará,",
        Real_mark10: "Certificación Básica",
        Real_mark11: "verificado",
        Real_mark12: "Certificación Avanzada",
        Real_mark13: "ir a autenticación",
        label_realName: "Nombre",
        message_realName: "por favor ingrese su nombre real",
        label_cardNumber: "numero de identidad",
        message_cardNumber: "Por favor ingrese número de identificación",
        upload_front: "Cargue el anverso de la tarjeta de identificación",
        upload_back: "Cargue el reverso de la tarjeta de identificación",
        upload_people: "Cargar ID de dispositivo portátil",
        under_review: "bajo revisión",
        security_setting: "Configuraciones de seguridad",
        my_bank: "Mi tarjeta bancaria",
        Change_Loginpsd: "Cambiar contraseña de inicio de sesión",
        Change_transactionpsd: "Modificar contraseña de transacción",
        email: "Correo",
        my: "mía",
        Bank: "Tarjeta bancaria",
        AddBank: "Añadir tarjeta bancaria",
        walletAddress: "dirección de la billetera",
        Default_currency: "moneda fiduciaria predeterminada",
        language: "idioma",
        share: "Cuota",
        logout: "abandonar",
        qrcode: "mía Qrcode",
        personal: "Informacion personal",
        identity: "Autenticación",
        nickname: "Apodo",
        nickname_tip: "2-10 caracteres, admite chino, inglés y números",
        Add_newAccount: "añadir nueva cuenta",
        label_user: "Nombre del usuario",
        label_number: "Número de tarjeta bancaria",
        label_branch: "Dirección bancaria",
        label_bank: "Nombre del Banco",
        label_swift: "Codificación Swift",
        label_address: "Dirección del usuario",
    },
    Task: {
        daily_tasks: "tareas diarias",
        activity_today: "actividades de hoy",
        task_list: "lista de tareas",
        weekly_tasks: "tarea semanal",
        activity: "Actividad",
        undone: "inconcluso",
        receive: "tomar el control",
        task_rules: "reglas de la misión",
        task_rules1: "1： Las actividades se acumulan y superponen en una semana, y se borrarán y liquidarán automáticamente a las 0:00 todos los lunes,",
        task_rules2: "2： Complete las 4 tareas diarias todos los días para obtener 5 monedas CCA después de alcanzar los 100 niveles de actividad;",
        task_rules3: "3： Cuando la actividad llega a 350, puedes obtener 10 monedas CCA",
        task_rules4: "4： Cuando la actividad llega a 700, puede obtener la experiencia de la máquina de minería N1 durante 1 día",
        sign_task: "tarea de inicio de sesión",
        Sign_everyDay: "iniciar sesión diariamente",
        CCA_coin: "moneda CCA",
        signIn: "Login",
        day: "cielo",
        daily_checkIn: "registro diario",
        put_away: "guardar",
        Expand: "expandir",
        signIn_now: "inicia sesión inmediatamente",
        checkIn_rules: "Reglas de registro",
        checkIn_rules1: "1： Inicie sesión durante 5 días consecutivos para obtener 5 monedas CCA",
        checkIn_rules2: "2： Inicie sesión durante 14 días consecutivos para obtener 20 monedas CCA",
        checkIn_rules3: "3： Regístrese durante 21 días consecutivos y recompense a la máquina de minería N1 con tres días de experiencia",
        checkIn_rules4: "4： Después de 24 días consecutivos de check-in, mantenga el registro de check-in ininterrumpido y puede obtener 5 monedas CCA por cada check-in",
    },
    Transaction: {
        delegate_List: "lista encargada",
        risk_rate: "tasa de riesgo",
        total_income: "Beneficio total de la posición",
        oneClick_closing: "Cerrar una posición con un clic",
        optional: "Opcional",
        all_closed: "Cerrar todas las posiciones",
        flat_multiple: "Solo pedidos planos largos",
        flat_empty: "Cerrar solo órdenes cortas",
        purchase: "comprar",
        sellOut: "vender",
        market_price: "precio de mercado",
        fixed_price: "límite de precio",
        trading_hands: "lotes comerciales",
        multiple: "múltiple",
        bond: "Margen",
        Service_Charge: "tasa de tramitación",
        balance: "balance",
        long: "hacer más",
        Short: "pequeño",
        Price: "precio",
        number: "cantidad",
        type: "Tipos de",
        time: "hora",
        operation: "funcionar",
        Transaction_pair: "par comercial",
        direction: "dirección",
        search: "buscar",
        Latest_price: "Último precio",
        Rise_fall: "El ascenso y la caída de hoy",
        entrust: "confiar",
        all: "todas",
        entrust_time: "tiempo de comisión",
        state: "Expresar",
        Completed: "terminado",
        air_completed: "Terminar",
        place_amount: "Por favor ingrese el contenido",
        turnover: "cantidad de transacción",
        contract: "contrato",
        option: "opciones",
        coins: "Moneda",
        High_today: "alto hoy",
        Low_today: "bajo hoy",
        Entrusted_bill: "Encomendar orden pendiente",
        Buy_more: "comprar más",
        Buy_less: "comprar menos",
        buy: "Comprar",
        sell: "Vender",
        crm_order: "Confirmación del pedido",
        Current_price: "precio actual",
        expiration_time: "Elija un tiempo de caducidad",
        Option_position: "posiciones de opciones",
        position: "posición",
        history: "historia",
        Purchase_price: "precio de compra",
        Purchase_amount: "Importe de la compra",
        Order_duration: "duración del pedido",
        Selling_time: "tiempo de venta",
        Profit_loss: "Ganancia y perdida",
        countdown: "Cuenta atrás",
        order_number: "Número de pedido",
        currency: "Moneda",
        reales_Number: "Transacciones reales",
        total_price: "Precio total",
        close_price: "Precio de cierre",
        volume_trade: "Volumen de Comercio",
        hand_fee: "Comisiones",
        continue_order: "Continuar haciendo pedidos",
    },
    footer: {
        home: "página delantera",
        market: "cotizaciones",
        transaction: "comercio",
        financial: "Finanzas",
        fund: "activos",
        rule: "regla",
        select_language: "Por favor selecciona idioma"
    },
    common: {
        noData: "Sin datos relevantes",
        Exchange: "intercambio",
        Cancel: "Cancelar",
        Confirm: "confirmar",
        Submit: "entregar",
        more: "Más",
        add: "Añadir",
        please_select: "Seleccione una opción",
        no_data: "No hay datos disponibles",
    },
    script: {
        error_msg: "Número/letra" ,
        register_noAccount: "¡Introduzca el teléfono o el correo electrónico del usuario!" ,
        send_phone_scuccess: "¡Envío de SMS con éxito!" ,
        register_success: "¡Registro correcto!" ,
        please_enter_amount: "¡Introduzca el importe!" ,
        please_enter_money: "¡Introduzca el importe de la compra!" ,
        success: "¡Éxito!" ,
        bankId_msg: "Por favor, seleccione el monedero" ,
        amount_msg: "Por favor, introduzca el importe" ,
        password_msg: "Por favor, introduzca su contraseña" ,
        redemption_msg: "¿Confirmar canje anticipado?" ,
        tip: "Propina" ,
        redemption_success: "Canje correcto" ,
        canceled: "Cancelado" ,
        realname_submit_success: "¡Nombre real enviado correctamente!" ,
        logout_tip: "¿Seguro que quieres cancelar?" ,
        login_tip: "¿Seguro que quiere iniciar sesión?" ,
        signIn: "Iniciar sesión" ,
        closeout_tip: "¿Está seguro de que desea cerrar todas sus posiciones?" ,
        closeout_success: "¡Todas las posiciones cerradas con éxito!" ,
        cancel_tip: "¿Está seguro de que desea cancelar?" ,
        notice: "Aviso" ,
        insufficient_balance: "¡Saldo insuficiente!" ,
    },
};
