export default {
    account: {
        more: {
            service: "primeiros socorros",
            moreMin: "Não pode ser inferior ao montante mínimo de levantamento",
            placePrefix: "Seleccione por favor um código de país",
        },
        aboutUs: {
            lint1: "At IDEX, we look at investing differently. Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing.",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality.",
            lint3: "IDEX is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products.",
            lint4: "IDEX strictly abides by the laws and regulations of various regions. We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets. ",
            lint5: "IDEX is one of the fastest growing fintech companies. We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market.",
        },
        billTypes: [
            {v: 101, nome: 'Recharge'},
            {v: 102, nome: 'Depósito'},
            {v: 103, nome: 'Congelar'},
            {v: 104, nome: 'Thaw'},
            {v: 105, nome: 'Depósito'},
            {v: 106, nome: 'Depósito'},
            {v: 201, nome: 'Congelamento de retirada'},
            {v: 202, nome: 'Retirada'},
            {v: 203, nome: 'Retirada bem sucedida'},
            {v: 204, name: 'Retirada falhou'},
            {v: 205, nome: "Taxa de retirada"},
            {v: 206, nome: 'Transferência'},
            {v: 207, nome: 'Transferido para'},
            {v: 208, nome: 'Transferência de moeda para fora'},
            {v: 209, nome: 'Transferência de moeda em'},
            {v: 301, nome: "Taxa de tratamento de divisas"},
            {v: 302, nome: "Rendimentos contratuais"},
            {v: 303, nome: 'Perda contratual'},
            {v: 311, nome: 'Opção de compra'},
            {v: 312, nome: 'Option Returns'},
            {v: 313, nome: 'Option Reembolso'},
            {v: 314, name: 'Option Handling Fee'},
            {v: 315, name: 'Currency Purchase Freeze'},
            {v: 316, nome: "dedução de compra em moeda" },
            {v: 317, name: 'Currency Purchase Return'},
            {v: 318, nome: 'Moeda comprada em conta'},
            {v: 319, nome: 'Moeda comprada em conta'},
            {v: 320, nome: "Congelamento das vendas de divisas"},
            {v: 321, nome: "dedução de vendas em moeda" },
            {v: 322, nome: 'Currency Sales Return'},
            {v: 323, nome: 'Moeda vendida à conta'},
            {v: 324, nome: 'Moeda vendida à conta'},
            {v: 325, nome: "Taxa de tratamento de divisas"},
            {v: 401, nome: 'Máquina de mineração adicionada'},
            {v: 402, name: 'Miner return'},
            {v: 403, nome: "Receitas de máquinas de mineração"},
            {v: 404, nome: 'Miner Exit'},
            {v: 405, nome: 'Taxa de saída da máquina de mineração'},
            {v: 501, name: 'Blind Box Purchase'},
            {v: 502, nome: 'Blind Box Revenue'},
        ],
        withdrawTypes: [
            {v: 0, nome: 'Aplicando'},
            {v: 1, nome: "Processamento"},
            {v: 2, nome: 'Sucesso'},
            {v: 3, name: 'Failed'},
        ],
        types: [
            { v: 4, name: 'Contas de opções' },
            { v: 3, name: 'Contas em moeda francesa' },
            { v: 2, name: 'Conta de Contratos' },
            { v: 1, name: 'Contas de divisas' },
        ],
        login: {
            aboutUs: "Sobre nós",
            notice: "Para recarregar facilmente o serviço, contacte o serviço de apoio ao cliente em linha.",
            login: "Conecte-se",
            selectLanguage: "Escolha um idioma",
            placeEmail: "E-mail ou telemóvel",
            placePsd: "senha",
            noAccount: "Sem conta?",
            register: "registro",
            btnLogin: "Conecte-se",
            psd_error: "Por favor introduza novamente a sua palavra-passe",
            psd_error2: "Duas palavras-passe inconsistentes",
            email_error: "A caixa de correio não pode estar vazia!",
            email_error2: "Formato de correio electrónico incorrecto!",
            forget: "Esqueça?",
        },
        register: {
            register: "registro",
            selectLanguage: "Escolha um idioma",
            placeEmail: "Correspondência",
            placePsd: "senha",
            placeCrmPsd: "Confirme a Senha",
            placeInviteId: "Código de Convite",
            haveAccount: "já tem uma conta?",
            login: "Conecte-se",
            btnRegister: "registro",
            getCode: "Obter código de verificação",
            code: "Código de verificação",
            psd_error: "Introduza novamente a sua senha",
            psd_error2: "Duas senhas inconsistentes",
            email_error: "A caixa de correio não pode estar vazia!",
            email_error2: "Formato de e- mail incorrecto!",
            elail_register: "E- mail",
            phone_register: "Telefone",
            country: "País",
            placePhone: "Telefone",
            countryCode: "Código do país",
            pleaseEnter: "Por favor, introduza"
        },
        forget: {
            title: "Senha Esquecida",
            labelEmail: "E-mail",
            labelEmailCode: "Código de verificação por correio electrónico",
            placeEmailCode: "Código de verificação por correio electrónico",
        },
        forgetPay: {
            title: "Esqueceu- se da Senha da Transacção",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "registros de loteria",
        },
        Blind_box_voucher: {
            title: "comprovante",
            voucher: "comprovante",
            currentVoucher: "credenciais atuais",
        },
        Blind_box: {
            title: "caixa cega",
            goodluck: "boa sorte",
            panelTit: "Selecione uma caixa cega abaixo",
            tip1: "1 sorteio deve ganhar 2500 cupons",
            tip2: "Você tem mais 2500 para obter",
            limit1: "desenho restante de hoje",
            limit2: "10 vezes",
            times: "vezes",
            draw: "pintura",
            discount: "Desconto",
            payable: "a pagar",
            blind_box: "caixa cega",
            available: "acessível",
            lotteryRecord: "registros de loteria",
            Determine_blind_box: "Determinar a caixa cega",
            tip3: "2. Um sorteio de loteria é limitado a 10 vezes por dia, e não são permitidos mais de 5 sorteios consecutivos",
            tip4: "3. Cupons de resgate podem ser resgatados por BTC e ETH na bolsa",
            tip5: "4. Cada loteria ganhará o prêmio",
            tip6: "5. 5 levantamentos USDT superiores a 10000 vezes devem obter um 'voucher de resgate', 10 levantamentos USDT superiores a 5000 vezes devem obter um 'voucher conversível', e 20 saques USDT superiores a 2500 vezes devem obter um 'voucher de troca'",
            tip7: "$5 Bônus e Probabilidade da Loteria:",
            tip8: "1. 15-200 OTD com uma probabilidade de 50%",
            tip9: "2. 1-5 EOS com uma probabilidade de 10%",
            tip10: "3. 5-30XRP com uma probabilidade de 10%",
            tip11: "4. 1-10 USDT com uma probabilidade de 10%",
            tip12: "5. 10~50PAGE, probabilidade de 10%",
            tip13: "6. 3 ETH com uma probabilidade de 5%",
            tip14: "7. 10 ETH com uma probabilidade de 2%",
            tip15: "8. 20ETH com uma probabilidade de 2%",
            tip16: "9. 2 BTC, probabilidade 1%",
            tip17: "10 Bônus e Probabilidade da Loteria USDT:",
            tip18: "1. 30~600 OTD, probabilidade 50%",
            tip19: "2. 1~20 EOS com uma probabilidade de 10%",
            tip20: "3. 10~80XRP com uma probabilidade de 10%",
            tip21: "4. 5-30 USD/ton, com uma probabilidade de 10%",
            tip22: "5. 20~100PAGE, probabilidade de 10%",
            tip23: "6. 20 ETH com uma probabilidade de 4%",
            tip24: "7. 1 BTC com uma probabilidade de 4%",
            tip25: "8. 2 BTC, probabilidade 2%",
            tip26: "20 Bônus e Probabilidade da Loteria USDT:",
            tip27: "1. 5-50USDT com uma probabilidade de 30%",
            tip28: "2. 1-50 ADA com uma probabilidade de 30%",
            tip29: "3. 1~20 EOS com uma probabilidade de 10%",
            tip30: "4. 3DOT com uma probabilidade de 5%",
            tip31: "5. 1 XMR com uma probabilidade de 5%",
            tip32: "6. 1 BCH com uma probabilidade de 5%",
            tip33: "7. 1 ETH com uma probabilidade de 5%",
            tip34: "8. 10000 DOGE com uma probabilidade de 5%",
            tip35: "9. 2 BTC, 3% de probabilidade",
            tip36: "10. 5 BTC com uma probabilidade de 2%",
            tip37: "Método do Sorteio:",
            tip38: "1. Empate uma vez por 5 USDT, duas vezes por 10 USDT, uma vez por 20 USDT, e aproveite um desconto de 5% para 5 sorteios consecutivos",
        },
    },
    Financial: {
        index: {
            finance: "finança",
            ieo: "IEO",
            LockMining: "Bloquear mineração",
            day: "céu",
            unit: "ascender",
            Daily_yield: "ascender",
        },
    },
    Follow: {
        index: {
            title: "lista de comerciantes",
            tip: "Os dados são atualizados a cada hora",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "recordes financeiros",
            number: "quantidade",
            time: "Tempo",
        },
        Assets_record: {
            title: "Detalhes da conta",
            total_assets: "Total de ativos equivalentes",
            Available_Credit: "Quantidade disponível",
            locking: "bloqueio",
            Financial_records: "recordes financeiros",
            number: "quantidade",
            time: "Tempo",
        },
        Assets: {
            Account_record: "Registro de mudança de conta",
            Recharge: "recarrega",
            Collection: "coletar dinheiro",
            Withdrawal: "retirar o",
            transfer: "transferir",
            Currency_transfer: "transferência de moeda",
            Coins: "Moeda",
            contract: "contrato",
            Legal_currency: "moeda francesa",
            option: "opções",
            Equivalent: "converter",
        },
        FundTransfer: {
            title: "transferência de fundos",
            Select_Currency: "Escolha a moeda",
            from: "A partir de",
            Transfer_quantity: "valor da transferência",
            to: "para",
        },
        Receive_withdraw_record: {
            title: "Registro",
        },
        Receive: {
            title: "recarrega",
            Digital_currency: "moeda digital",
            tip: "Entre em contato com o atendimento ao cliente para solicitar o endereço de recarga",
            next: "Próxima Etapa",
            Recharge_address: "endereço de recarga",
            Recharge_number: "Valor da recarga",
            place_Recharge_number: "Por favor, insira o valor da recarga",
            Payment_voucher: "comprovante de pagamento",
            place_Payment_voucher: "Insira o comprovante de pagamento",
        },
        Transfer: {
            Currency_transfer: "transferência de moeda",
            Capital_transfer: "transferência de fundos",
            from: "A partir de",
            Transfer_number: "valor da transferência",
            need_service_charge: "taxa de manuseio adicional",
            to: "para",
            will_get: "você vai ter",
            Available_Credit: "Quantidade disponível",
            Exchange_rate: "taxa de câmbio",
            Service_Charge: "taxa de manuseio",
        },
        Withdraw: {
            title: "transferir",
            Withdrawal: "contrato",
            bank_card: "cartão do banco",
            wallet_address: "Mencione o endereço da carteira",
            Withdrawal_number: "Quantidade retirada",
            available: "acessível",
            place_Withdrawal_number: "Por favor, insira o valor da retirada",
            all: "todos",
            Minimum_Withdrawal_number: "Valor mínimo de retirada",
            password: "Senha da conta",
            Service_Charge: "taxa de manuseio",
        }
    },
    Guess: {
        title: "loteria",
        min: "o menor",
        Bullish: "ascender",
        Bearish: "cair",
        Guess_amount: "Adivinhe a quantia",
        place_Guess_amount: "Por favor, insira o valor",
        Current_selection: "seleção atual",
        Payable: "a pagar",
        Guess_now: "adivinhe agora",
        Available: "acessível",
        Lottery_record: "registros de loteria",
        Betting_record: "registro de apostas",
        Start_price: "preço inicial",
        End_price: "preço final",
        Game_introduction: "introdução do jogo",
        tip1: "1. O questionário é dividido em três períodos: 10 minutos, 3 minutos e 1 minuto. o preço do BTC sobe e desce. o preço do BTC é o preço médio da ligação tripla Coinbase+Binance+Huobi, o que é uma novela completamente justa。",
        tip2: "2. Aqui está um exemplo de 10 minutos: os primeiros 10 minutos de cada período é o tempo para fazer uma encomenda, os últimos 2 minutos é o tempo de licitação, o último 1 minuto é o anúncio do preço de licitação, a licitação > o preço de licitação é 'para cima'. Quando a hora chega às 00:13, o preço do BTC é 35000 USDT, então o seu preço marcado é: 35000 USDT. E às 00:14 o preço do resultado publicado é de 35001 USDT, por isso o palpite é 'para cima' e adivinhou correctamente, é 'em alta'. Parabéns pela sua adivinhação. Receberá um lucro de $950. O sistema vai-se contentar por 1950 USDT, dos quais 50 USDT é a plataforma e o seu lucro é de 950 USDT.",
        tip3: "3. As probabilidades são de 1:0.95, porque não 1:1, uma vez que a plataforma cobra uma comissão de 5%.",
        tip4: "4. No final do questionário, os resultados serão resolvidos. O dinheiro que ganha é-lhe dado pelo perdedor. Para garantir que a plataforma não está envolvida em jogos de azar entre utilizadores, se houver muitos vencedores e poucos perdedores, terá de esperar que os perdedores apareçam antes de entregar os seus ganhos. Por exemplo, quando apostar 100.000 USDT e adivinhar que ganhou, deverá receber 95.000 USDT. No entanto, apenas 80.000 USDT são perdidos e o pool de entrega não tem dinheiro suficiente para lhe pagar 95.000 USDT. terá de esperar que o pool de entrega mostre o dinheiro do perdedor. Assim que atingir 15.000 USDT, a plataforma liquidará os seus 95.000 USDT de bónus。",
        tip5: "5. Se realmente não pode esperar e quer receber o seu bónus rapidamente, pode fazer uma entrega rápida, mas a entrega rápida receberá apenas 25% dos 95.000 USDT, o que significa que só pode receber 2375 USDT, pois a entrega rápida é equivalente ao seu bónus geral. O poder é transferido para a plataforma, depois a plataforma precisa de assumir o risco relativo, pelo que a plataforma precisa de deduzir uma enorme taxa。",
        tip6: "Esta P&R não se trata de apostas entre a plataforma e o utilizador, e se encontrar tal situação, pode comunicá-la imediatamente.",
    },
    home: {
        title: "",
        Total_assets: "Total de ativos equivalentes",
        Recharge: "recarrega",
        documentary: "documentário",
        transaction: "troca",
        IEO: "IEO",
        Lock_mining: "Bloquear mineração",
        Investment_Financing: "Investimento e gestão financeira",
        Currency_transfer: "transferência de moeda",
        Currency_swap: "Обмен",
        Withdrawal: "contrato",
        Blind_box: "caixa cega",
        Guess: "adivinhar",
        Daily_tasks: "tarefas diárias",
        Sign_task: "assinar tarefa",
        Buy_coins_quickly: "Compre moedas rapidamente",
        Buy_quickly: "compra rápida",
        day: "céu",
        rise: "ascender",
        Daily_yield: "Taxa diária de retorno",
        quotation: "citações",
        Transaction_pair: "par de negociação",
        Latest_price: "preço mais recente",
        Rise_fall: "Ascensão e queda de hoje",
    },
    ieo: {
        ieo: "Assinatura IEO",
        Lock_up_cycle: "período de bloqueio",
        day: "céu",
        Closed: "sobre",
        Completed: "concluído",
        Subscribed: "Subscrito",
        surplus: "restante",
        My_subscription: "minha assinatura",
        Subscription_time: "tempo de assinatura",
        currency: "Moeda",
        Requested_number: "Número de aplicativos",
        Pass_number: "passar quantidade",
        Market_time: "hora de comercializar",
    },
    Invest: {
        conduct: "gestão financeira",
        Special_area: "área especial",
        Annualized_rate: "Taxa de retorno anualizada",
        Linked_price: "preço de referência atrelado",
        Remaining_share: "parte restante",
        Position_duration: "Período de espera",
        due_date: "data de validade",
        My_conduct: "minhas finanças",
        all: "todos",
        fail: "falhar",
        Purchased: "comprou",
        settlement: "Assentamento",
        Completed: "concluído",
    },
    Lockming: {
        Minimum_single: "mínimo único",
        Daily_yield: "Taxa diária de retorno",
        Lock_up_cycle: "período de bloqueio",
        payout_time: "tempo de dividendo",
        everyday: "diariamente",
        Custody_funds: "Fundos de caução",
        Return_expiration: "retorno devido",
        called_away: "resgate antecipado",
        placeholder: "Insira o conteúdo",
        all: "todos",
        Estimated_income: "Renda estimada",
        Available_assets: "ativos disponíveis",
        subscription: "se inscrever",
        Lock_mining: "Bloquear mineração",
        Funds_custody: "Fundos sob custódia",
        Estimated_yield_today: "Rendimento Estimado Hoje",
        Accumulated_income: "Renda acumulada",
        Orders_custody: "Pedidos sob custódia",
        Delegated_Order: "pedido comissionado",
        Lock: "Trancar",
        day: "céu",
        Lock_list: "lista de bloqueio",
        Exited: "Sair",
        Redeemed: "redimido",
        have_hand: "em processamento",
        redeem: "redenção",
        Amount_currency: "valor do depósito",
        Lockup_time: "tempo de bloqueio",
        Expiration_time: "Data de validade",
        early_redemption: "Danos liquidados de resgate antecipado",
    },
    Market: {
        Optional: "opcional",
        Transaction_pair: "par de negociação",
        Latest_price: "preço mais recente",
        place_search: "Pesquise o nome/código da moeda/ações de seu interesse",
    },
    Setting: {
        label_currency: "Escolha a moeda",
        place_currency: "Selecione a moeda",
        label_walletTitle: "nome da carteira",
        place_walletTitle: "Insira um nome de carteira",
        label_walletAddress: "endereço da carteira",
        place_walletAddress: "Insira o endereço da carteira",
        Add_bankCard: "adicionar cartão de banco",
        label_name: "nome real",
        label_depositBank: "banco",
        label_bankCard: "Número do cartão bancário",
        label_beneficary_address: "Endereço do beneficiário",
        label_payee_name: "Nome do beneficiário",
        label_bank_name: "Nome do Banco",
        label_swift_code: "código bancário",
        APP_download: "Certificação MSB",
        label_currentPassword: "Senha atual",
        place_currentPassword: "Por favor, digite a senha atual",
        label_newPassword: "Nova senha de login",
        place_newPassword: "Insira uma nova senha de login",
        label_confirmationPassword: "Confirme a nova senha",
        place_confirmationPassword: "Digite para confirmar a nova senha",
        label_newPassword1: "Palavra-passe da transacção",
        place_newPassword1: "Introduza a sua palavra-passe da transacção",
        payment_notice: "Nota: O valor inicial por defeito da palavra-passe da transacção é a palavra-passe de acesso actual",
        Real_mark1: "identificação pessoal",
        Real_mark2: "Permissões disponíveis após a certificação:",
        Real_mark3: "Lv1.Certificação básica",
        Real_mark4: "Pode ser refletido após a certificação, limite de 24 horas 200BTC",
        Real_mark5: "Após a autenticação, podem ser realizadas transações em moeda legal, com um limite de transação única de 2000USDT",
        Real_mark6: "Lv2.Certificação Avançada",
        Real_mark7: "Aumente o valor refletido, o limite de 24 horas é 500BTC",
        Real_mark8: "Aumente o limite de transação em moeda fiduciária, um limite único de 1.000.000 USDT",
        Real_mark9: "Lembrete: Para proteger a segurança dos fundos do usuário, conclua primeiro a vinculação de e-mail e telefone, caso contrário, sua autenticação falhará.",
        Real_mark10: "Certificação básica",
        Real_mark11: "verificado",
        Real_mark12: "Certificação Avançada",
        Real_mark13: "ir para autenticação",
        label_realName: "Nome",
        message_realName: "por favor digite seu nome verdadeiro",
        label_cardNumber: "número de identificação",
        message_cardNumber: "Por favor insira o número de identificação",
        upload_front: "Carregue a frente do cartão de identificação",
        upload_back: "Carregue o verso do cartão de identificação",
        upload_people: "Carregar ID do dispositivo portátil",
        under_review: "sob revisão",
        security_setting: "Configurações de segurança",
        my_bank: "O meu cartão bancário",
        Change_Loginpsd: "Alterar senha de login",
        Change_transactionpsd: "Modificar a senha da transação",
        email: "Correspondência",
        my: "meu",
        Bank: "cartão bancário",
        AddBank: "Adicionar Cartão Bancário",
        walletAddress: "endereço da carteira",
        Default_currency: "moeda fiduciária padrão",
        language: "Língua",
        share: "compartilhar",
        logout: "Sair",
        qrcode: "meu Qrcode",
        personal: "Informações pessoais",
        identity: "Autenticação",
        nickname: "Apelido",
        nickname_tip: "2-10 caracteres, suporta chinês, inglês e números",
        Add_newAccount: "adicionar nova conta",
        label_user: "Nome do Utilizador",
        label_number: "Número do cartão bancário",
        label_branch: "Endereço bancário",
        label_bank: "Nome do Banco",
        label_swift: "Codificação rápida",
        label_address: "Endereço do Utilizador",
    },
    Task: {
        daily_tasks: "tarefas diárias",
        activity_today: "atividades de hoje",
        task_list: "lista de tarefas",
        weekly_tasks: "tarefa semanal",
        activity: "Atividade",
        undone: "inacabado",
        receive: "assumir",
        task_rules: "regras da missão",
        task_rules1: "1： As atividades são acumuladas e sobrepostas dentro de uma semana, e serão automaticamente limpas e reajustadas às 0:00 todas as segundas-feiras",
        task_rules2: "2： Complete todas as 4 tarefas diárias todos os dias para obter 5 moedas CCA após atingir 100 níveis de atividade;",
        task_rules3: "3： Quando a atividade atinge 350, você pode obter 10 moedas CCA",
        task_rules4: "4： Quando a atividade atingir 700, você poderá obter a experiência da máquina de mineração N1 por 1 dia",
        sign_task: "tarefa de login",
        Sign_everyDay: "logar diariamente",
        CCA_coin: "moeda CCA",
        signIn: "Conecte-se",
        day: "céu",
        daily_checkIn: "check-in diário",
        put_away: "jogue fora",
        Expand: "expandir",
        signIn_now: "faça login imediatamente",
        checkIn_rules: "Regras de check-in",
        checkIn_rules1: "1： Faça login por 5 dias consecutivos para obter 5 moedas CCA",
        checkIn_rules2: "2： Faça login por 14 dias consecutivos para obter 20 moedas CCA",
        checkIn_rules3: "3： Entre por 21 dias consecutivos e recompense a máquina de mineração N1 com três dias de experiência",
        checkIn_rules4: "4： Após 24 dias consecutivos de check-in, mantenha o registo de check-in ininterrupto e poderá obter 5 moedas CCA por cada check-in",
    },
    Transaction: {
        delegate_List: "lista comissionada",
        risk_rate: "taxa de risco",
        total_income: "Lucro total da posição",
        oneClick_closing: "Feche uma posição com um clique",
        optional: "opcional",
        all_closed: "Fechar todas as posições",
        flat_multiple: "Somente ordens longas planas",
        flat_empty: "Fechar apenas ordens curtas",
        purchase: "Comprar",
        sellOut: "vender",
        market_price: "preço de mercado",
        fixed_price: "limite de preço",
        trading_hands: "lotes de negociação",
        multiple: "múltiplo",
        bond: "Margem",
        Service_Charge: "taxa de manuseio",
        balance: "Saldo",
        long: "faça mais",
        Short: "baixo",
        Price: "preço",
        number: "quantidade",
        type: "Tipos de",
        time: "Tempo",
        operation: "operar",
        Transaction_pair: "par de negociação",
        direction: "direção",
        search: "procurar",
        Latest_price: "preço mais recente",
        Rise_fall: "Ascensão e queda de hoje",
        entrust: "confiar",
        all: "todos",
        entrust_time: "tempo de comissão",
        state: "Estado",
        Completed: "concluído",
        air_completed: "Terminar",
        place_amount: "Insira o conteúdo",
        turnover: "valor da transação",
        contract: "contrato",
        option: "opções",
        coins: "Moeda",
        High_today: "alta hoje",
        Low_today: "baixo hoje",
        Entrusted_bill: "Confiar pedido pendente",
        Buy_more: "Compre mais",
        Buy_less: "compre menos",
        buy: "comprar",
        sell: "vender",
        crm_order: "Confirmação do pedido",
        Current_price: "preço atual",
        expiration_time: "Escolha um tempo de expiração",
        Option_position: "posições de opção",
        position: "posição",
        history: "história",
        Purchase_price: "preço de compra",
        Purchase_amount: "Montante da compra",
        Order_duration: "duração do pedido",
        Selling_time: "tempo de venda",
        Profit_loss: "lucros e perdas",
        Hand_count: "Contagem de mãos",
        countdown: "Contagem regressiva",
        order_number: "Número de ordem",
        currency: "Moeda",
        reales_Number: "Transacção efectiva",
        total_price: "Preço total",
        close_price: "Preço de encerramento",
        volume_trade: "Volume das trocas comerciais",
        hand_fee: "Taxa de entrega",
        continue_order: "Continuar a fazer uma encomenda",
    },
    footer: {
        home: "primeira página",
        market: "citações",
        transaction: "troca",
        financial: "finança",
        fund: "ativos",
        rule: "regra",
        select_language: "Escolha um idioma"
    },
    common: {
        noData: "Nenhum dado relevante",
        Exchange: "intercâmbio",
        Cancel: "Cancelar",
        Confirm: "confirme",
        Submit: "enviar",
        more: "Mais",
        add: "adicionar a",
        Cover: "ocultação",
        Success: "sucesso",
        portion: "share",
        day: "dia",
        please_select: "Por favor seleccione",
        no_data: "Não há dados disponíveis",
    },
    script: {
        error_msg: "Número/letra" ,
        register_noAccount: "Por favor, introduza o telefone ou e-mail do utilizador!" ,
        send_phone_scuccess: "Envio de SMS com sucesso!" ,
        register_success: "Registo bem sucedido!" ,
        please_enter_amount: "Por favor introduza o montante!" ,
        please_enter_money: "Por favor, introduza o montante da compra!" ,
        sucesso: "Sucesso!" ,
        bankId_msg: "Por favor seleccione a carteira" ,
        montante_msg: "Por favor, introduza o montante" ,
        password_msg: "Por favor, introduza a sua password" ,
        redemption_msg: "Confirmar redenção antecipada?" ,
        dica: "Dica" ,
        redemption_success: "Redemption successful! (Redenção bem sucedida!)" ,
        cancelado: "Cancelado" ,
        realname_submit_success: "Submissão_sucesso_de_nome real!" ,
        logout_tip: "Tem a certeza que quer cancelar?" ,
        signIn: "Login" ,
        closeout_tip: "Tem a certeza de que quer fechar todas as suas posições?" ,
        closeout_success: "Todas as posições fechadas com sucesso!" ,
        cancel_tip: "Tem a certeza que quer cancelar?" ,
        aviso: "Aviso" ,
        insufficient_balance: "Insufficient_balance!" ,
    },
};
