export default {
    account: {
        more: {
            service: "Customer Service",
            moreMin: "Must not be less than the minimum cash withdrawal amount",
            placePrefix: "Please select country code",
        },
        aboutUs: {
            lint1: "At IDEX, we look at investing differently. Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing.",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality.",
            lint3: "IDEX is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products.",
            lint4: "IDEX strictly abides by the laws and regulations of various regions. We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets. ",
            lint5: "IDEX is one of the fastest growing fintech companies. We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market.",
        },
        billTypes: [
            {v: 101, name: 'Recharge'},
            {v: 102, name: 'Deposit'},
            {v: 103, name: 'Freeze'},
            {v: 104, name: 'Thaw'},
            {v: 105, name: 'Deposit'},
            {v: 106, name: 'Deposit'},
            {v: 201, name: 'Withdrawal freeze'},
            {v: 202, name: 'Withdrawal'},
            {v: 203, name: 'Withdrawal succeeded'},
            {v: 204, name: 'Withdrawal failed'},
            {v: 205, name: 'Withdrawal handling fee'},
            {v: 206, name: 'Transfer out'},
            {v: 207, name: 'Transferred in'},
            {v: 208, name: 'Currency transfer out'},
            {v: 209, name: 'Currency transfer in'},
            {v: 301, name: 'Contract handling fee'},
            {v: 302, name: 'Contract Revenue'},
            {v: 303, name: 'Contract loss'},
            {v: 311, name: 'Option Purchase'},
            {v: 312, name: 'Option earnings'},
            {v: 313, name: 'Option Return'},
            {v: 314, name: 'Option Fee'},
            {v: 315, name: 'Coin buy freeze'},
            {v: 316, name: 'Coin buy deduction'},
            {v: 317, name: 'Coin buy back'},
            {v: 318, name: 'Coin buy-in received'},
            {v: 319, name: 'Coin buy-in received!'},
            {v: 320, name: 'Coin sell freeze'},
            {v: 321, name: 'Coins sold deducted'},
            {v: 322, name: 'Sell coins back'},
            {v: 323, name: 'Coins sold to account'},
            {v: 324, name: 'Coins sold to the account!'},
            {v: 325, name: 'Coin pending order fee!'},
            {v: 401, name: 'Mining machine added'},
            {v: 402, name: 'Mining Machine Return'},
            {v: 403, name: 'Mining Machinery Revenue'},
            {v: 404, name: 'Miner Exit'},
            {v: 405, name: 'Mining machine exit fee'},
            {v: 501, name: 'Blind Box Purchase'},
            {v: 502, name: 'Blind Box Revenue'},
        ],
        withdrawTypes: [
            { v: 0, name: 'APPLY' },
            { v: 1, name: 'PENDING' },
            { v: 2, name: 'SUCCESS' },
            { v: 3, name: 'FAILED' },
        ],
        types: [
            { v: 4, name: 'Options Account' },
            { v: 3, name: 'Fiat Account' },
            { v: 2, name: 'Contract Account' },
            { v: 1, name: 'Coins Account' },
        ],
        login: {
            aboutUs: "About Us",
            notice: "For convenient service recharge please contact online customer service.",
            login: "Log In",
            selectLanguage: "Language",
            placeEmail: "E-mail or cell phone",
            placePsd: "Password",
            noAccount: "Don't have an account ? Go",
            register: "Sign Up",
            btnLogin: "Log In",
            forget: "Forgot Password?",
        },
        register: {
            register: "Create Account",
            selectLanguage: "Language",
            placeEmail: "Email Address",
            placePsd: "Password",
            placeCrmPsd: "Confirm Password",
            placeInviteId: "Invitation Code",
            haveAccount: "Already Have An Account ? Go",
            login: "Sign In",
            btnRegister: "Sign Up",
            getCode: "Send",
            code: "Verification Code",
            psd_error: "Please enter your password again",
            psd_error2: "Two inconsistent passwords",
            email_error: "The mailbox cannot be empty!",
            email_error2: "Incorrect email format!",
            elail_register: "Email",
            phone_register: "Phone Number",
            country: "Country",
            placePhone: "Phone Number",
            countryCode: "Country code",
            pleaseEnter: "Search"
        },
        forget: {
            title: "Password Forgot",
            labelEmail: "E-mail",
            labelEmailCode: "E-mail Verification Code",
            placeEmailCode: "E-mail Verification Code",
        },
        forgetPay: {
            title: "Transaction Password Forgot",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "Lottery Record",
        },
        Blind_box_voucher: {
            title: "Voucher",
            voucher: "Voucher",
            currentVoucher: "Current Voucher",
        },
        Blind_box: {
            title: "Blind Box",
            goodluck: "GOOD LUCK",
            panelTit: "Please Choose A Blind Box Below",
            tip1: "You Must Win 2500 Coupon For 1 Draw",
            tip2: "You Still Have 2500 Times To Get It",
            limit1: "Draws Remaining Today",
            limit2: "10 Times",
            times: "Times",
            draw: "Draw",
            discount: "Discount",
            payable: "Payable",
            blind_box: "Blind Box",
            available: "Available",
            lotteryRecord: "Lottery Record",
            Determine_blind_box: "Determine Blind Box",
            tip3: "2. A single lottery draw is limited to 10 times a day, and there is no limit to 5 consecutive draws",
            tip4: "3. The redemption coupon can be exchanged for BTC and ETH in EXCHANG",
            tip5: "4. Every lottery will win prizes",
            tip6: "5. 5 USDT draws over 10,000 times must get 'exchange vouchers', 10 USDT draws over 5000 times must get 'exchange vouchers', 20 USDT draws over 2500 times must get 'exchange vouchers'",
            tip7: "5 USDT lottery prizes and probability:",
            tip8: "1. 15~200 OTD, probability 50%",
            tip9: "2. 1~5 EOS, the probability is 10%",
            tip10: "3. 5~30XRP, probability 10%",
            tip11: "4. 1~10 USDT, the probability is 10%",
            tip12: "5. 10~50DOGE, probability 10%",
            tip13: "6. 3 ETH, probability of 5%",
            tip14: "7. 10 ETH, probability 2%",
            tip15: "8. 20ETH, the probability is 2%",
            tip16: "9. 2 BTC, probability 1%",
            tip17: "10 USDT raffle prizes and probability:",
            tip18: "1. 30~600 OTD, probability 50%",
            tip19: "2. 1~20EOS, the probability is 10%",
            tip20: "3. 10~80XRP, the probability is 10%",
            tip21: "4. 5~30 USDT, probability 10%",
            tip22: "5. 20~100DOGE, probability 10%",
            tip23: "6. 20 ETH, probability 4%",
            tip24: "7. 1 BTC, probability 4%",
            tip25: "8. 2 BTC, probability 2%",
            tip26: "20 USDT raffle prizes and probability:",
            tip27: "1. 5~50USDT, probability 30%",
            tip28: "2. 1~50 ADA, probability 30%",
            tip29: "3. 1~20EOS, the probability is 10%",
            tip30: "4. 3DOT, probability of 5%",
            tip31: "5. 1 XMR, probability of 5%",
            tip32: "6. 1 BCH, probability of 5%",
            tip33: "7. 1 ETH, probability of 5%",
            tip34: "8. 10000 DOGE, probability of 5%",
            tip35: "9. 2 BTC, 3% probability",
            tip36: "10. 5 BTC, probability 2%",
            tip37: "Lucky draw method:",
            tip38: "1. 5 USDT draw once, 10 USDT draw once, 20 USDT draw once, five consecutive draws can enjoy a 5% discount",
        },
    },
    Financial: {
        index: {
            finance: "Finance",
            ieo: "IEO",
            LockMining: "Lock In Mining",
            day: "Day",
            unit: "Rise",
            Daily_yield: "Daily Yield",
        },
    },
    Follow: {
        index: {
            title: "Trader List",
            tip: "Data Is Updated Every Hour",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "History",
            number: "Amount",
            time: "Time",
        },
        Assets_record: {
            title: "History",
            total_assets: "My Assets",
            Available_Credit: "Available",
            locking: "Pending",
            Financial_records: "History",
            number: "Amount",
            time: "Time",
        },
        Assets: {
            Account_record: "History",
            Recharge: "Deposit",
            Collection: "Collection",
            Withdrawal: "Withdrawal",
            transfer: "Transfer",
            Currency_transfer: "Convert",
            Coins: "Coins",
            contract: "Contract",
            Legal_currency: "Fiat Currency",
            option: "Option",
            Equivalent: "Equivalent",
        },
        FundTransfer: {
            title: "Capital Transfer",
            Select_Currency: "Select Currency",
            from: "From",
            Transfer_quantity: "Transfer Quantity",
            to: "To",
        },
        Receive_withdraw_record: {
            title: "Record",
        },
        Receive: {
            title: "Deposit",
            Digital_currency: "Crypto Currency",
            tip: "Contact Customer Service To Ask For The Recharge Address",
            next: "Next Step",
            Recharge_address: "Wallet Address",
            Recharge_number: "Deposit Amount",
            place_Recharge_number: "Please Enter The Deposit Amount",
            Payment_voucher: "Payment Receipt",
            place_Payment_voucher: "Please Enter The Payment Receipt",
        },
        Transfer: {
            Currency_transfer: "Convert",
            Capital_transfer: "Capital Transfer",
            from: "From",
            Transfer_number: "Amount",
            need_service_charge: "Free",
            to: "To",
            will_get: "You Will Get",
            Available_Credit: "Available Credit",
            Exchange_rate: "Exchange Rate",
            Service_Charge: "Service Charge",
        },
        Withdraw: {
            title: "Withdrawal",
            Withdrawal: "Withdrawal",
            bank_card: "Bank Card",
            wallet_address: "Wallet Address",
            Withdrawal_number: "Withdrawal Amount",
            available: "Available",
            place_Withdrawal_number: "Please Enter The Withdrawal Quantity",
            all: "All",
            Minimum_Withdrawal_number: "Min Withdrawal Amount",
            password: "Withdrawal Password",
            Service_Charge: "Service Charge",
        },
    },
    Guess: {
        title: "Guess",
        min: "Min",
        Bullish: "Bullish",
        Bearish: "Bearish",
        Guess_amount: "Guess Amount",
        place_Guess_amount: "Please Enter The Amount",
        Current_selection: "Current Selection",
        Payable: "Payable",
        Guess_now: "Guess Now",
        Available: "Available",
        Lottery_record: "Lottery Record",
        Betting_record: "Betting Record",
        Start_price: "Start Price",
        End_price: "End Price",
        Game_introduction: "Game Introduction",
        tip1: "1. The quiz is divided into three periods: 10 minutes, 3 minutes, and 1 minute. BTC rises and falls. The price of BTC is the average price of the three linkages of Coinbase+Binance+Huobi, which is truly a completely fair novel.",
        tip2: "2. Here is an example of 10 minutes: the first 10 minutes of each period is the time for placing an order, the next 2 minutes is the bidding price, and the next 1 minute is the announcement of the bidding price, and bidding> bidding price is 'price increase'. , The bid price the guess price is 'decline', for example: 00:00~00:15, here is the first period starting on the same day, where at 00:00 you placed an order for BTC 'bullish' and the order amount is 1000 USDT. When the time reaches 00:13, the price of BTC is 35000 USDT, then your mark price is: 35000 USDT, and the published result price at 00:14 is 35001 USDT, then the guess result is 'up', and you guessed it It is 'bullish'. Congratulations on your guess. You will get a profit of 950 US dollars. The system will settle you 1950 US dollars T, of which 50 US dollars T is the platform, and you made a profit of 950 US dollars T.",
        tip3: "3. The odds are 1:0.95, why not 1:1? Because the platform needs to charge a 5% handling fee.",
        tip4: "4. After the quiz is over, the result will be settled. The money you win is given to you by the loser. In order to ensure that the platform does not participate in the gambling behavior between users, if there are more winners and fewer losers, You need to wait for the loser to show up before you can deliver the bonus. For example: when you bet 100,000 USDT and guess that you win, you should get 95,000 USDT. However, only 80,000 USDT is lost, and the delivery pool funds are not enough to pay your 95000 USDT. You need to wait for the delivery pool to show the loser’s funds. Once you reach 15000 USDT, the platform will settle your 95000 bonus.",
        tip5: "5. If you really can’t wait for the bonus you want to take away quickly, you can make fast delivery, but you can only receive 25% of the 95000USDT for fast delivery, which means you can only take away 2375USDT, because fast delivery is equivalent to your general bonus. The power is transferred to the platform, then the platform needs to bear the relative risk, so the platform needs to deduct a huge fee.",
        tip6: "This quiz does not involve gambling between the platform and the user, and you can report it immediately if you find it so.",
    },
    home: {
        title: "Biboxcoin",
        // title: "Biboxcoin",
        Total_assets: "Total Balance",
        Recharge: "Deposit",
        documentary: "Documentary",
        transaction: "Transaction",
        IEO: "IEO",
        Lock_mining: "Mining",
        Investment_Financing: "Investment Financing",
        Currency_transfer: "Convert",
        Currency_swap: "Swap",
        Withdrawal: "Withdrawal",
        Blind_box: "Blind Box",
        Guess: "Guess",
        Daily_tasks: "Daily Tasks",
        Sign_task: "Sign Task",
        Buy_coins_quickly: "Buy Coins Quickly",
        Buy_quickly: "Buy Quickly",
        day: "Day",
        rise: "Rise",
        Daily_yield: "Daily Yield",
        quotation: "Quotation",
        Transaction_pair: "Name",
        Latest_price: "Latest Price",
        Rise_fall: "Change",
    },
    ieo: {
        ieo: "IEO Subscription",
        Lock_up_cycle: "Lock Up Cycle",
        day: "Day",
        Closed: "Closed",
        Completed: "Completed",
        Subscribed: "Subscribed",
        surplus: "Surplus",
        My_subscription: "My Subscription",
        Subscription_time: "Subscription Time",
        currency: "Currency",
        Requested_number: "Requested Quantity",
        Pass_number: "Number Of Passes",
        Market_time: "Market Time",
    },
    Invest: {
        conduct: "Conduct Financial Transactions",
        Special_area: "Special Area",
        Annualized_rate: "Annualized Rate",
        Linked_price: "Linked Price",
        Remaining_share: "Remaining Share",
        Position_duration: "Position Duration",
        due_date: "Due Date",
        My_conduct: "My Financial Management",
        all: "All",
        fail: "Fail",
        Purchased: "Purchased",
        settlement: "Settlement",
        Completed: "Completed",
    },
    Lockming: {
        Minimum_single: "Min Amount",
        Lock_up_cycle: "Waiting Time",
        payout_time: "Time",
        everyday: "Everyday",
        Custody_funds: "Escrow Funds",
        Return_expiration: "Return Upon Expiration",
        called_away: "Early Withdrawal",
        placeholder: "Please Rnter The Content",
        all: "All",
        Estimated_income: "Estimated Income",
        Available_assets: "Available Assets",
        subscription: "Confirm",
        Lock_mining: "Lock In Mining",
        Funds_custody: "Funds Under Custody",
        Estimated_yield_today: "Estimated Yield Today",
        Accumulated_income: "Accumulated Income",
        Orders_custody: "Orders In Custody",
        Delegated_Order: "Delegated Order",
        Lock: "Lock",
        day: "Day",
        Lock_list: "Lock List",
        Exited: "Exited",
        Redeemed: "Redeemed",
        have_hand: "Have In Hand",
        redeem: "Redeem",
        Amount_currency: "Amount",
        Daily_yield: "Daily Yield",
        Lockup_time: "Lockup Time",
        Expiration_time: "Expiration Time",
        early_redemption: "Liquidated Damages For Early Redemption",
    },
    Market: {
        Optional: "Optional",
        Transaction_pair: "Name",
        Latest_price: "Latest Price",
        place_search: "Search For The Currency/Stock Name/Code You Care About",
    },
    Setting: {
        label_currency: "Select Currency",
        place_currency: "Please Select A Currency",
        label_walletTitle: "Wallet Name",
        place_walletTitle: "Please Enter The Wallet Name",
        label_walletAddress: "Wallet Address",
        place_walletAddress: "Please Enter The Wallet Address",
        Add_bankCard: "Add Bank Card",
        label_name: "Real Name",
        label_depositBank: "Bank Of Deposit",
        label_bankCard: "Bank Card No",
        label_beneficary_address: "Beneficiary Address",
        label_payee_name: "Name Of Payee",
        label_bank_name: "Bank Name",
        label_swift_code: "Bank Code",
        APP_download: "MSB Certification",
        label_currentPassword: "Current Password",
        place_currentPassword: "Please Enter The Current Password",
        label_newPassword: "Login Password",
        place_newPassword: "Please Enter A Login Password",
        label_newPassword1: "Transactional Password",
        place_newPassword1: "Please Enter A Transactional Password",
        label_confirmationPassword: "Confirm New Password",
        place_confirmationPassword: "Please Enter A New Password",
        payment_notice: "Notice: The initial default of the transaction password is the current login password",
        Real_mark1: "Services And Limits :",
        Real_mark2: "Permissions available after completing authentication:",
        Real_mark3: "Lv1. Basic Verification",
        Real_mark4: "Features and limits",
        Real_mark5: "Deposit &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unlimited",
        Real_mark5_1: "Withdrawal &nbsp;&nbsp;&nbsp; 1BTC",
        Real_mark5_2: "Trade &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2,000 USD Daily",
        Real_mark6: "Lv2. Advanced Verification",
        Real_mark7: "Features  and limits",
        Real_mark8: "Deposit &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Unlimited",
        Real_mark8_1: "Withdrawal &nbsp;&nbsp;&nbsp; 100BTC",
        Real_mark8_2: "Trade &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 1,000,000 USD Daily",
        Real_mark9: "Warmly note: To protect the safety of user funds, please complete the binding of email and phone first, otherwise your authentication will not be passed.",
        Real_mark10: "Basic Verification",
        Real_mark11: "Completed  Verify",
        Real_mark12: "Advanced Verification",
        Real_mark13: "Verify",
        label_realName: "Full Name",
        message_realName: "Please Enter Your Full Name",
        label_cardNumber: "ID Number",
        message_cardNumber: "Please Enter ID Number",
        upload_front: "Upload The Front Of ID Card",
        upload_back: "Upload The Back Of ID Card",
        upload_people: "Photo With ID Card in Hand",
        under_review: "Under Review",
        security_setting: "Change Password",
        security_setting_title: "Setting",
        my_bank: "My Bank Card",
        Change_Loginpsd: "Change Login Password",
        Change_transactionpsd: "Modify Transaction Password",
        email: "Email",
        my: "My",
        walletAddress: "Wallet Address",
        Bank: "Bank",
        AddBank: "Add Bank",
        Default_currency: "Default Legal Currency",
        language: "Language",
        share: "Share",
        logout: "Logout",
        qrcode: "MY Qrcode",
        personal: "Personal Information",
        identity: "Verify identity",
        nickname: "Nickname",
        nickname_tip: "2-10 Characters, Supporting Chinese And English, Numbers",
        Add_newAccount: "Add New Address",
        label_user: "Customer Name",
        label_number: "Bank Number",
        label_branch: "Bank Address",
        label_bank: "Bank Name",
        label_swift: "Swift/Swift Code/BIC",
        label_address: "Beneficiary Address",
    },
    Task: {
        daily_tasks: "Daily Tasks",
        activity_today: "Activity Today",
        task_list: "Task List",
        weekly_tasks: "Weekly Tasks",
        activity: "Activity",
        undone: "Undone",
        receive: "Receive",
        task_rules: "Task Rules",
        task_rules1: "1：The Activity Is Accumulated And Superimposed Within A Week, And It Is Automatically Cleared And Re-settled Every Monday At 0:00 A.M.；",
        task_rules2: "2：Complete All 4 Daily Tasks Every Day And Get 5 CCA Coins After Reaching 100 Activity Level；",
        task_rules3: "3：When The Activity Reaches 350, You Can Receive 10 CCA Coins；",
        task_rules4: "4： When The Activity Reaches 700, You Can Experience N1 Mining Machine For 1 Day",
        sign_task: "Sign In Task",
        Sign_everyDay: "Sign In Every Day",
        CCA_coin: "CCA Coin",
        signIn: "Sign In",
        day: "Day",
        daily_checkIn: "Daily Check-in",
        put_away: "Put Away",
        Expand: "Expand",
        signIn_now: "Sign In Now",
        checkIn_rules: "Check-in Rules",
        checkIn_rules1: "1：5 CCA Coins Will Be Awarded For Consecutive Sign-in For 5 Days；",
        checkIn_rules2: "2：20 CCA Coins Will Be Awarded For Consecutive Sign-in For 14 Days；",
        checkIn_rules3: "3：Sign In For 21 Consecutive Days To Reward N1 Mining Machine Experience Time For Three Days；",
        checkIn_rules4: "4：Keep The Check-in Record Uninterrupted After 24 Consecutive Days Of Check-in, And You Can Get 5 CCA Coins For Daily Check-in；",
    },
    Transaction: {
        delegate_List: "Delegate List",
        risk_rate: "Risk Rate",
        total_income: "Total Position Income",
        oneClick_closing: "One Click Closing",
        optional: "Optional",
        all_closed: "All Positions Closed",
        flat_multiple: "Only Flat And Multiple Orders",
        flat_empty: "Only Flat And Empty Bill",
        purchase: "Buy",
        sellOut: "Sell",
        market_price: "Market Price",
        fixed_price: "Limited Price",
        trading_hands: "Trading Hands",
        multiple: "Multiple",
        bond: "Bond",
        Service_Charge: "Service Charge",
        balance: "Balance",
        long: "Long",
        Short: "Short",
        Price: "Price",
        number: "Number",
        type: "Type",
        time: "Time",
        operation: "Operation",
        Transaction_pair: "Name",
        direction: "Direction",
        search: "Search",
        Latest_price: "Latest Price",
        Rise_fall: "Change",
        entrust: "Entrust",
        all: "All",
        entrust_time: "Entrust Time",
        state: "State",
        Completed: "Completed",
        air_completed: "Air Completed",
        place_amount: "Enter The Amount",
        turnover: "Turnover",
        contract: "Contract",
        option: "Option",
        coins: "Coins",
        High_today: "High Today",
        Low_today: "Low Today",
        Entrusted_bill: "Entrusted Bill",
        Buy_more: "Buy More",
        Buy_less: "Buy Less",
        buy: "Buy",
        sell: "Sell",
        crm_order: "Order",
        Current_price: "Current Available Price",
        expiration_time: "Select Expiration Time",
        Option_position: "Order History",
        position: "Situation",
        history: "History",
        Purchase_price: "Order Price",
        Purchase_amount: "Amount",
        Order_duration: "Type",
        Selling_time: "Time",
        Profit_loss: "P/L",
        Hand_count: "Hand count",
        countdown: "Countdown",
        order_number: "Order number",
        currency: "Currency",
        reales_Number: "Actual transaction",
        total_price: "Total",
        close_price: "Closing Price",
        volume_trade: "Volume of trade",
        hand_fee: "Handing fee",
        continue_order: "Continue to place an order",
    },
    footer: {
        home: "Home",
        market: "Market",
        transaction: "Transaction",
        financial: "Financial",
        fund: "My Assets",
        rule: "Rule",
        select_language: "Please Select A Language"
    },
    common: {
        noData: "No Relevant Fata Yet",
        Exchange: "Exchange",
        Cancel: "Cancel",
        Confirm: "Confirm",
        Submit: "Submit",
        more: "More",
        add: "Add",
        Cover: "Cover",
        Success: "Success",
        portion: "Portion",
        day: "Day",
        please_select: "Please Select",
        no_data: "No Data",
    },
    script: {
        error_msg: "Digits/Letters",
        error_msg_address: "Error Address",
        error_msg_bank: "Must be at least 6-20 characters long",
        error_msg_password: "Password must be at least 6-20 characters long and contain at least  uppercase,  lowercase letter and digit. ",
        register_noAccount: "Please enter the phone number or email.",
        register_captcha: "Please enter the verification code",
        send_phone_scuccess: "Send Successed!",
        register_success: "Register Successed",
        please_enter_amount: "Please Enter Amount",
        please_enter_money: "Please Enter Buy Money",
        success: "SUCCESS!",
        bankId_msg: "Please Select Wallet",
        amount_msg: "Please Enter The Amount",
        password_msg: "Please Enter Your Password",
        password_withdraw_msg: "Please Enter Your Withdrawal Password",
        redemption_msg: "Confirm Early Redemption?",
        tip: "Tip",
        redemption_success: "Successful Redemption!",
        canceled: "Canceled",
        realname_submit_success: "Realname Submit Successed!",
        logout_tip: "Are You Sure To Log Out？",
        login_tip: "Are You Sure You Want To Sign In",
        signIn: "Sign In",
        closeout_tip: "Determine All Closed Positions?",
        closeout_success: "All Positions Closed Successfully!",
        cancel_tip: "Are You Sure Cancel?",
        notice: "Notice",
        insufficient_balance: "Insufficient Balance!",
    },
};
