export default {
    account: {
        more: {
            service: "primo soccorso",
            moreMin: "Deve essere non inferiore all'importo minimo di prelievo",
            placePrefix: "Seleziona un codice paese",
        },
        aboutUs: {
            lint1: "At IDEX, we look at investing differently, Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing,",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality,",
            lint3: "IDEX is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products,",
            lint4: "IDEX strictly abides by the laws and regulations of various regions, We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets, ",
            lint5: "IDEX is one of the fastest growing fintech companies, We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market,",
        },
        billTypes: [
            {v: 101, nome: 'ricarica' },
            {v: 102, nome: 'Deposito'},
            {v: 103, nome: 'Congelare'},
            {v: 104, nome: 'Scongelare'},
            {v: 105, nome: 'Deposito'},
            {v: 106, nome: 'Deposito'},
            {v: 201, nome: 'Prelievo congelato'},
            {v: 202, nome: 'Prelievo'},
            {v: 203, nome: "Prelievo riuscito"},
            {v: 204, nome: 'Prelievo non riuscito'},
            {v: 205, nome: 'Prelievo a pagamento'},
            {v: 206, nome: 'Trasferimento in uscita'},
            {v: 207, nome: "Trasferimento in entrata"},
            {v: 208, nome: 'Trasferimento di monete in uscita'},
            {v: 209, nome: 'Trasferimento monete'},
            {v: 301, nome: 'Costo monete'},
            {v: 302, nome: 'Ricavo da contratto'},
            {v: 303, nome: 'Perdite da contratto'},
            {v: 311, nome: 'Opzione di acquisto'},
            {v: 312, nome: 'Guadagno da opzione'},
            {v: 313, nome: "Ritorno dell'opzione"},
            {v: 314, nome: 'Commissione di opzione'},
            {v: 315, nome: 'Blocco acquisto monete'},
            {v: 316, nome: 'Acquisto di monete con addebito'},
            {v: 317, nome: 'Acquisto monete di ritorno'},
            {v: 318, nome: 'Acquisto monete ricevuto'},
            {v: 319, nome: 'Acquisto monete restituito'},
            {v: 320, nome: 'Vendita monete bloccata'},
            {v: 321, nome: 'Monete vendute dedotte'},
            {v: 322, nome: 'Vendita monete in restituzione'},
            {v: 323, nome: 'Monete vendute al conto'},
            {v: 324, nome: 'Monete rivendute'},
            {v: 325, nome: 'Commissione monete' },
            {v: 401, nome: 'Miner join'},
            {v: 402, nome: 'Ritorno del minatore'},
            {v: 403, nome: 'reddito del minatore'},
            {v: 404, nome: 'Miner exit'},
            {v: 405, nome: "Tassa di uscita del minatore"},
            {v: 501, nome: 'Acquisti in scatola cieca'},
            {v: 502, nome: "Entrate dalla cassa cieca"},
        ],
        withdrawTypes: [
            { v: 0, nome: 'Applicazione in corso' },
            { v: 1, nome: 'Elaborazione' },
            { v: 2, nome: "Successo" },
            { v: 3, nome: "Non riuscito" },
        ],
        types: [
            { v: 4, name: 'conto opzioni' },
            { v: 3, name: 'Conto in valuta Fiat' },
            { v: 2, name: 'Conto contratti' },
            { v: 1, name: 'Conto in valuta' },
        ],
        login: {
            aboutUs: "Chi siamo",
            notice: "Per ricaricare facilmente il servizio si prega di contattare il servizio clienti online,",
            login: "login",
            selectLanguage: "Seleziona lingua",
            placeEmail: "Email o cellulare",
            placePsd: "Password",
            noAccount: "Nessun conto? vai",
            register: "registro",
            btnLogin: "Login",
            forget: "Dimentica?",
        },
        register: {
            register: "registro",
            selectLanguage: "Seleziona lingua",
            placeEmail: "Email",
            placePsd: "Password",
            placeCrmPsd: "Conferma password",
            placeInviteId: "Codice invito",
            haveAccount: "Hai gia' un conto? vai",
            login: "login",
            btnRegister: "registro",
            getCode: "Ottenere il codice di verifica",
            code: "Codice di verifica",
            psd_error: "Inserire nuovamente la password",
            psd_error2: "Due password incoerenti",
            email_error: "La cassetta postale non può essere vuota!",
            email_error2: "Formato e-mail errato!",
            elail_register: "Email",
            phone_register: "telefono",
            country: "Paese",
            placePhone: "telefono",
            countryCode: "Codice paese",
            pleaseEnter: "Inserisci"
        },
        forget: {
            title: "Password dimenticata",
            labelEmail: "E-mail",
            labelEmailCode: "Codice di verifica e-mail",
            placeEmailCode: "Codice di verifica e-mail",
        },
        forgetPay: {
            title: "Password della transazione dimenticata",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "Record della lotteria",
        },
        Blind_box_voucher: {
            title: "Voucher",
            voucher: "Voucher",
            currentVoucher: "Voucher attuale",
        },
        Blind_box: {
            title: "Blind Box",
            goodluck: "Buona fortuna",
            panelTit: "Scegli una scatola cieca qui sotto",
            tip1: "Devi vincere 2500 coupon per 1 estrazione",
            tip2: "Hai ancora 2500 volte per prenderlo",
            limit1: "Estrazioni ancora oggi",
            limit2: "10 volte",
            times: "Tempi",
            draw: "Disegna",
            discount: "Sconto",
            payable: "Pagabile",
            blind_box: "Scatola cieca",
            available: "Disponibile",
            lotteryRecord: "Record della lotteria",
            Determine_blind_box: "Determina il riquadro cieco",
            tip3: "2, Un'estrazione della lotteria è limitata a 10 volte al giorno e non sono consentite più di 5 estrazioni consecutive",
            tip4: "3, I coupon di riscatto possono essere riscattati per BTC ed ETH presso lo SCAMBIO",
            tip5: "4, Ogni lotteria vincerà il premio",
            tip6: "5, 5 prelievi USDT superiori a 10000 volte devono ottenere un 'buono di riscatto', 10 prelievi USDT superiori a 5000 volte devono ottenere un 'buono convertibile', e 20 prelievi USDT superiori a 2500 volte devono ottenere un 'buono di cambio'",
            tip7: "$5 Bonus Lotteria e Probabilità:",
            tip8: "1, 15-200 OTD con una probabilità del 50%",
            tip9: "2, 1-5 EOS con una probabilità del 10%",
            tip10: "3, 5-30XRP con una probabilità del 10%",
            tip11: "4, 1-10 USDT con una probabilità del 10%",
            tip12: "5, 10~50PAGE, probabilità del 10%",
            tip13: "6, 3 ETH con una probabilità del 5%",
            tip14: "7, 10 ETH con una probabilità del 2%",
            tip15: "8, 20ETH con una probabilità del 2%",
            tip16: "9, 2 BTC, probabilità 1%",
            tip17: "10 USDT Lottery Bonus e Probabilità:",
            tip18: "1, 30~600 OTD, probabilità 50%",
            tip19: "2, 1~20 EOS con una probabilità del 10%",
            tip20: "3, 10~80XRP con una probabilità del 10%",
            tip21: "4, 5-30 USD/ton, con una probabilità del 10%",
            tip22: "5, 20~100PAGE, probabilità del 10%",
            tip23: "6, 20 ETH con una probabilità del 4%",
            tip24: "7, 1 BTC con una probabilità del 4%",
            tip25: "8, 2 BTC, probabilità 2%",
            tip26: "20 USDT Lottery Bonus e Probabilità:",
            tip27: "1, 5-50USDT con una probabilità del 30%",
            tip28: "2, 1-50 ADA con una probabilità del 30%",
            tip29: "3, 1~20 EOS con una probabilità del 10%",
            tip30: "4, 3DOT con una probabilità del 5%",
            tip31: "5, 1 XMR con una probabilità del 5%",
            tip32: "6, 1 BCH con una probabilità del 5%",
            tip33: "7, 1 ETH con una probabilità del 5%",
            tip34: "8, 10000 DOGE con una probabilità del 5%",
            tip35: "9, 2 BTC, 3% di probabilità",
            tip36: "10, 5 BTC con probabilità del 2%",
            tip37: "Metodo Lucky Draw:",
            tip38: "1, Estrai una volta per 5 USDT, due volte per 10 USDT, una volta per 20 USDT e ottieni uno sconto del 5% per 5 estrazioni consecutive",
        },
    },
    Financial: {
        index: {
            finance: "finanza",
            ieo: "IEO",
            LockMining: "Blocca l'estrazione mineraria",
            day: "giorno",
            unit: "aumento",
            Daily_yield: "Rendimento giornaliero",
        },
    },
    Follow: {
        index: {
            title: "Elenco degli operatori",
            tip: "I dati vengono aggiornati ogni ora",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "Documenti finanziari",
            number: "numero",
            time: "tempo",
        },
        Assets_record: {
            title: "Dettagli del conto",
            total_assets: "Totale delle attività convertite",
            Available_Credit: "Credito disponibile",
            locking: "In attesa",
            Financial_records: "Documenti finanziari",
            number: "numero",
            time: "tempo",
        },
        Assets: {
            Account_record: "Registrazione del cambio di conto",
            Recharge: "Ricarica",
            Collection: "Raccolta",
            Withdrawal: "Ritiro",
            transfer: "trasferimento",
            Currency_transfer: "Trasferimento di valuta",
            Coins: "Monete",
            contract: "contratto",
            Legal_currency: "Valuta legale",
            option: "opzione",
            Equivalent: "Equivalente",
        },
        FundTransfer: {
            title: "Trasferimento di capitale",
            Select_Currency: "Seleziona valuta",
            from: "da",
            Transfer_quantity: "Quantità di trasferimento",
            to: "a",
        },
        Receive_withdraw_record: {
            title: "record",
        },
        Receive: {
            title: "Ricarica",
            Digital_currency: "Moneta digitale",
            tip: "Contatta il servizio clienti per richiedere l'indirizzo di ricarica",
            next: "passo successivo",
            Recharge_address: "Indirizzo di ricarica",
            Recharge_number: "Quantità di ricarica",
            place_Recharge_number: "Inserisci la quantità di ricarica",
            Payment_voucher: "Voucher di pagamento",
            place_Payment_voucher: "Inserisci il voucher di pagamento",
        },
        Transfer: {
            Currency_transfer: "Trasferimento di valuta",
            Capital_transfer: "Trasferimento di capitale",
            from: "da",
            Transfer_number: "Quantità di trasferimento",
            need_service_charge: "Supplemento di servizio",
            to: "a",
            will_get: "You will get",
            Available_Credit: "Credito disponibile",
            Exchange_rate: "Tasso di cambio",
            Service_Charge: "Costo del servizio",
        },
        Withdraw: {
            title: "conti di trasferimento",
            Withdrawal: "Ritiro",
            bank_card: "carta di credito",
            wallet_address: "Indica l'indirizzo del portafoglio",
            Withdrawal_number: "Quantità di prelievo",
            available: "disponibile",
            place_Withdrawal_number: "Inserisci la quantità di prelievo",
            all: "Tutti",
            Minimum_Withdrawal_number: "Quantità minima di prelievo",
            password: "Password dell'account",
            Service_Charge: "Costo del servizio",
        },
    },
    Guess: {
        title: "Indovina",
        min: "min",
        Bullish: "Bullish",
        Bearish: "Bearish",
        Guess_amount: "Indovina l'importo",
        place_Guess_amount: "Inserisci l'importo",
        Current_selection: "Selezione corrente",
        Payable: "Pagabile",
        Guess_now: "Indovina ora,",
        Available: "Disponibile",
        Lottery_record: "Record della lotteria",
        Betting_record: "Record di scommesse",
        Start_price: "Prezzo iniziale",
        End_price: "Prezzo finale",
        Game_introduction: "Introduzione al gioco",
        tip1: "1, il quiz è diviso in tre periodi: 10 minuti, 3 minuti e 1 minuto, l'ascesa e la caduta del BTC, il prezzo del BTC è il prezzo medio di Coinbase+Binance+Huobi collegati tra loro, questo è un romanzo completamente equo,",
        tip2: "2, Ecco un esempio di 10 minuti: i primi 10 minuti di ogni periodo servono per piazzare ordini, i secondi 2 minuti servono per fare offerte, l'ultimo 1 minuto serve per annunciare il prezzo di offerta, e il prezzo di offerta > prezzo di offerta è 'up', Il prezzo di offerta > prezzo di offerta è 'al ribasso', ad esempio: 00:00~00:15, ecco il primo periodo all'inizio della giornata, alle 00:00 piazzate un ordine 'call' di BTC per 1000 USDT, Quando l'ora raggiunge le 00:13, il prezzo di BTC è 35000 USDT, quindi il vostro prezzo segnato è: 35000 USDT, E alle 00:14 il prezzo pubblicato è di 35001 USDT, quindi l'ipotesi è 'al rialzo' e avete indovinato, è 'rialzista' , Congratulazioni per la vostra ipotesi, Riceverete un profitto di 950 dollari, Il sistema vi liquiderà 1950 USDT, di cui 50 USDT sono la piattaforma e voi otterrete un profitto di 950 USDT" ,
        tip3: "3, Le probabilità sono 1:0,95, perché non 1:1? Perché la piattaforma applica una commissione del 5%" ,
        tip4: "4, I risultati saranno definiti alla fine del quiz, Il denaro vinto viene dato dal perdente, Per garantire che la piattaforma non sia coinvolta nel gioco d'azzardo tra utenti, se ci sono molti vincitori e pochi perdenti, dovrete aspettare che i perdenti appaiano prima di consegnare le vostre vincite, Ad esempio, se si scommette 100,000 USDT e si indovina una vincita, si dovrebbero ricevere 95,000 USDT, Tuttavia, solo 80,000 USDT sono stati persi e il pool di consegna non ha abbastanza denaro per pagare 95,000 USDT, Una volta raggiunti i 15,000 USDT, la piattaforma liquiderà il vostro bonus di 95,000" ,
        Suggerimento5: "5, Se proprio non potete aspettare e volete prendere il vostro bonus rapidamente, potete fare una consegna rapida, ma la consegna rapida riceverà solo il 25% dei 95000 USDT, il che significa che potrete prendere solo 2375 USDT, dato che la consegna rapida equivale al vostro bonus generale, Il potere viene trasferito alla piattaforma, che deve quindi assumersi il rischio relativo e dedurre un'enorme commissione" ,
        tip6: "Questa Q&A non riguarda le scommesse tra piattaforme e utenti, se trovate una situazione del genere, potete segnalarla immediatamente,",
    },
    home: {
        title: "Bibox",
        Total_assets: "Totale delle attività convertite",
        Recharge: "Ricarica",
        documentary: "documentario",
        transaction: "transazione",
        IEO: "IEO",
        Lock_mining: "Blocca l'estrazione mineraria",
        Investment_Financing: "Finanziamento degli investimenti",
        Currency_transfer: "Trasferimento di valuta",
        Currency_swap: "Swap",
        Withdrawal: "Ritiro",
        Blind_box: "Scatola cieca",
        Guess: "Indovina",
        Daily_tasks: "Attività giornaliere",
        Sign_task: "Firma attività",
        Buy_coins_quickly: "Compra monete velocemente",
        Buy_quickly: "Compra velocemente",
        day: "giorno",
        rise: "aumento",
        Daily_yield: "Rendimento giornaliero",
        quotation: "citazione",
        Transaction_pair: "Coppia di transazioni",
        Latest_price: "Ultimo prezzo",
        Rise_fall: "Alzati tutti",
    },
    ieo: {
        ieo: "Abbonamento IEO",
        Lock_up_cycle: "Ciclo di blocco",
        day: "giorno",
        Closed: "Chiuso",
        Completed: "Completato",
        Subscribed: "Sottoscritto",
        surplus: "eccedenza",
        My_subscription: "Il mio abbonamento",
        Subscription_time: "Durata dell'abbonamento",
        currency: "valuta",
        Requested_number: "Quantità richiesta",
        Pass_number: "Numero di pass",
        Market_time: "Tempo di mercato",
    },
    Invest: {
        conduct: "condurre operazioni finanziarie",
        Special_area: "Area speciale",
        Annualized_rate: "Tasso annuo",
        Linked_price: "Prezzo collegato",
        Remaining_share: "Quota residua",
        Position_duration: "Durata della posizione",
        due_date: "data di scadenza",
        My_conduct: "La mia gestione finanziaria",
        all: "tutti",
        fail: "fail",
        Purchased: "Acquistato",
        settlement: "regolamento",
        Completed: "Completato",
    },
    Lockming: {
        Minimum_single: "Operazione singola minima",
        Lock_up_cycle: "Ciclo di blocco",
        payout_time: "Tempo di pagamento",
        everyday: "tutti i giorni",
        Custody_funds: "Fondi di custodia",
        Return_expiration: "Restituzione alla scadenza",
        called_away: "chiamato via",
        placeholder: "Inserisci il contenuto",
        all: "tutti",
        Estimated_income: "Reddito stimato",
        Available_assets: "Attività disponibili",
        subscription: "abbonamento",
        Lock_mining: "Blocca l'estrazione mineraria",
        Funds_custody: "Fondi in custodia",
        Estimated_yield_today: "Rendimento stimato oggi",
        Accumulated_income: "Redditi accumulati",
        Orders_custody: "Ordini in custodia",
        Delegated_Order: "Ordine delegato",
        Lock: "Blocca",
        day: "giorno",
        Lock_list: "Elenco blocco",
        Exited: "Uscito",
        Redeemed: "Riscatta",
        have_hand: "avere in mano",
        redeem: "riscattare",
        Amount_currency: "Importo della moneta",
        Daily_yield: "Rendimento giornaliero",
        Lockup_time: "Tempo di chiusura",
        Expiration_time: "Tempo di scadenza",
        early_redemption: "Danni liquidati per rimborso anticipato",
    },
    Market: {
        Optional: "Facoltativo",
        Transaction_pair: "Coppia di transazioni",
        Latest_price: "Ultimo prezzo",
        place_search: "Cerca la valuta/il nome delle azioni/il codice che ti interessa",
    },
    Setting: {
        label_currency: "Seleziona valuta",
        place_currency: "Seleziona una valuta",
        label_walletTitle: "Nome portafoglio",
        place_walletTitle: "Inserisci il nome del portafoglio",
        label_walletAddress: "Indirizzo del portafoglio",
        place_walletAddress: "Inserisci l'indirizzo del portafoglio",
        Add_bankCard: "Aggiungi carta di credito",
        label_name: "Nome reale",
        label_depositBank: "Banca di deposito",
        label_bankCard: "Numero della carta di credito",
        label_beneficary_address: "Indirizzo del beneficiario",
        label_payee_name: "Nome del beneficiario",
        label_bank_name: "Nome della banca",
        label_swift_code: "codice bancario",
        APP_download: "Certificazione MSB",
        label_currentPassword: "Password attuale",
        place_currentPassword: "Inserisci la password corrente",
        label_newPassword: "Nuova password di accesso",
        place_newPassword: "Inserisci una nuova password di accesso",
        label_confirmationPassword: "Conferma nuova password",
        place_confirmationPassword: "Inserisci una nuova password per confermare",
        label_newPassword1: "Password di transazione",
        place_newPassword1: "Inserire la password della transazione",
        payment_notice: "Nota: il valore iniziale predefinito della password di transazione è la password di accesso corrente",
        Real_mark1: "Autenticazione dell'identità personale",
        Real_mark2: "Permessi disponibili dopo la certificazione:",
        Real_mark3: "Lv1,Certificazione di base",
        Real_mark4: "Può riflettersi dopo la certificazione, con un limite di 24 ore di 200BTC",
        Real_mark5: "Dopo l'autenticazione, le transazioni in valuta francese possono essere effettuate, con un unico limite di transazione di 2000USDT",
        Real_mark6: "Lv2,Certificazione avanzata",
        Real_mark7: "Aumenta il limite riflesso, con un limite di 24 ore di 500BTC",
        Real_mark8: "Aumentare il limite di transazione in valuta francese, con un limite unico di 1000000 USD",
        Real_mark9: "Suggerimenti: Per proteggere la sicurezza dei fondi degli utenti, completare prima l'associazione di email e telefono, altrimenti l'autenticazione fallirà,",
        Real_mark10: "Certificazione di base",
        Real_mark11: "Certificato",
        Real_mark12: "Certificazione avanzata",
        Real_mark13: "De certificazione",
        label_realName: "nome reale",
        message_realName: "Inserisci il tuo vero nome",
        label_cardNumber: "Numero identificativo",
        message_cardNumber: "Inserisci il numero identificativo",
        upload_front: "Carica la parte anteriore della carta d'identità",
        upload_back: "Carica il retro della carta d'identità",
        upload_people: "Carica la carta d'identità portatile",
        under_review: "In corso di revisione",
        security_setting: "impostazione di sicurezza",
        my_bank: "La mia carta di credito",
        Change_Loginpsd: "Cambia password di accesso",
        Change_transactionpsd: "Modifica la password della transazione",
        email: "email",
        my: "Il mio",
        Bank: "carta di credito",
        AddBank: "Aggiungi carta bancaria",
        walletAddress: "Indirizzo del portafoglio",
        Default_currency: "Valuta legale predefinita",
        language: "lingua",
        share: "quota",
        logout: "disconnessione",
        qrcode: "IL MIO Qrcode",
        personal: "informazioni personali",
        identity: "autenticazione dell'identità",
        nickname: "nickname",
        nickname_tip: "2-10 caratteri, supporto cinese e inglese, numeri",
        Add_newAccount: "Aggiungi un nuovo account",
        label_user: "Nome utente",
        label_number: "Numero della carta di credito",
        label_branch: "Indirizzo bancario",
        label_bank: "Nome della banca",
        label_swift: "Codifica rapida",
        label_address: "Indirizzo utente",
    },
    Task: {
        daily_tasks: "Attività giornaliere",
        activity_today: "Attività oggi",
        task_list: "Elenco attività",
        weekly_tasks: "Compiti settimanali",
        activity: "Attività",
        undone: "Annullato",
        receive: "ricevere",
        task_rules: "Regole delle attività",
        task_rules1: "1：L'attività viene accumulata e sovrapposta entro una settimana, e viene automaticamente cancellata e regolata ogni lunedì alle 0:00 del mattino,；",
        task_rules2: "2：Completa tutte e 4 le attività quotidiane ogni giorno e ottieni 5 monete CCA dopo aver raggiunto il livello di attività 100；",
        task_rules3: "：Quando l'attività raggiunge i 350, puoi ricevere 10 monete CCA；",
        task_rules4: "4： Quando l'attività raggiunge 700, è possibile sperimentare N1 mining machine per 1 giorno",
        sign_task: "Attività di accesso",
        Sign_everyDay: "Accedi ogni giorno",
        CCA_coin: "Moneta CCA",
        signIn: "Accedi",
        day: "Giorno",
        daily_checkIn: "check-in giornaliero",
        put_away: "Metti via",
        Expand: "Espandi",
        signIn_now: "Accedi ora",
        checkIn_rules: "Regole per il check-in",
        checkIn_rules1: "1：5 monete CCA verranno assegnate per l'accesso consecutivo per 5 giorni；",
        checkIn_rules2: "2：20 monete CCA saranno assegnate per l'accesso consecutivo per 14 giorni；",
        checkIn_rules3: "3：Accedi per 21 giorni consecutivi per premiare il tempo di esperienza della macchina mineraria N1 per tre giorni；",
        checkIn_rules4: "4：Mantieni il registro del check-in ininterrotto dopo 24 giorni consecutivi di check-in e puoi ottenere 5 monete CCA per il check-in giornaliero；",
    },
    Transaction: {
        delegate_List: "Elenco delegati",
        risk_rate: "Tasso di rischio",
        total_income: "Totale reddito da posizione",
        oneClick_closing: "Chiusura con un clic",
        optional: "facoltativo",
        all_closed: "Tutte le posizioni chiuse",
        flat_multiple: "Solo ordini piatti e multipli",
        flat_empty: "Solo bolletta piatta e vuota",
        purchase: "acquisto",
        sellOut: "esaurito",
        market_price: "prezzo di mercato",
        fixed_price: "prezzo fisso",
        trading_hands: "trading hands",
        multiple: "multiplo",
        bond: "bond",
        Service_Charge: "Costo del servizio",
        balance: "saldo",
        long: "lungo",
        Short: "Breve",
        Price: "Prezzo",
        number: "numero",
        type: "tipo",
        time: "tempo",
        operation: "operazione",
        Transaction_pair: "Coppia di transazioni",
        direction: "direzione",
        search: "ricerca",
        Latest_price: "Ultimo prezzo",
        Rise_fall: "Rise fall",
        entrust: "affidare",
        all: "tutti",
        entrust_time: "affida tempo",
        state: "stato",
        Completed: "Completato",
        air_completed: "aria completata",
        place_amount: "Inserisci il contenuto",
        turnover: "fatturato",
        contract: "contratto",
        option: "opzione",
        coins: "monete",
        High_today: "Alto oggi",
        Low_today: "Basso oggi",
        Entrusted_bill: "Legge di affidamento",
        Buy_more: "Compra di più",
        Buy_less: "Compra meno",
        buy: "acquistare",
        sell: "vendere",
        crm_order: "conferma dell'ordine",
        Current_price: "Prezzo corrente",
        expiration_time: "Seleziona tempo di scadenza",
        Option_position: "Posizione dell'opzione",
        position: "posizione",
        history: "storia",
        Purchase_price: "Prezzo d'acquisto",
        Purchase_amount: "Importo dell'acquisto",
        Order_duration: "Durata dell'ordine",
        Selling_time: "Tempo di vendita",
        Profit_loss: "Utile e perdite",
        Hand_count: "Numero delle mani",
        countdown: "Conto alla rovescia",
        order_number: "Numero d'ordine",
        currency: "Valuta",
        reales_Number: "Operazione effettiva",
        total_price: "Prezzo totale",
        close_price: "Prezzo di chiusura",
        volume_trade: "Volume degli scambi",
        hand_fee: "Tassa di consegna",
        continue_order: "Continua a effettuare un ordine",
    },
    footer: {
        home: "casa",
        market: "Mercato",
        transaction: "Operazione",
        financial: "Finanziamento",
        fund: "Fondo",
        rule: "Regola",
        select_language: "Seleziona una lingua"
    },
    common: {
        noData: "Ancora nessun dato rilevante",
        Exchange: "Scambio",
        Cancel: "Annulla",
        Confirm: "Conferma",
        Submit: "Invia",
        more: "di più",
        add: "Aggiungi a",
        Cover: "occultamento",
        Success: "successo",
        portion: "quota",
        day: "giorno",
        please_select: "Selezionare",
        no_data: "Nessun dato disponibile",
    },
    script: {
        error_msg: "Numero/lettera" ,
        register_noAccount: "Inserire il telefono o l'e-mail dell'utente!" ,
        send_phone_scuccess: "Invio SMS riuscito!" ,
        register_success: "Registrazione riuscita!" ,
        please_enter_amount: "Inserire l'importo!" ,
        please_enter_money: "Inserire i fondi per l'acquisto!" ,
        success: "Successo!" ,
        bankId_msg: "Selezionare il portafoglio" ,
        amount_msg: "Inserire l'importo" ,
        password_msg: "Inserire la password" ,
        redemption_msg: "Confermare il riscatto anticipato?" ,
        tip: "Suggerimento" ,
        redemption_success: "Riscatto riuscito!" ,
        canceled: "Annullato" ,
        realname_submit_success: "Invio del nome reale riuscito!" ,
        logout_tip: "Sei sicuro di voler annullare?" ,
        login_tip: "Sei sicuro di voler accedere?" ,
        signIn: "Login" ,
        closeout_tip: "Sei sicuro di voler chiudere tutte le tue posizioni?" ,
        closeout_success: "Tutte le posizioni sono state chiuse con successo!" ,
        cancel_tip: "Sei sicuro di voler annullare?" ,
        notice: "Avviso" ,
        insufficient_balance: "Saldo insufficiente!" ,
    },
};
