export default {
    account: {
        more: {
            service: "응급 처치",
            moreMin: "최소 출금액 이상이어야 합니다",
            placePrefix: "국가 번호를 선택하십시오.",
        },
        aboutUs: {
            lint1: "At IDEX, we look at investing differently. Today's financial system is complex, exclusive and expensive, making it difficult for the average person to start investing.",
            lint2: "So we're building a platform without complex barriers, a platform that enables you to see new money possibilities and help them become a reality.",
            lint3: "IDEX is designed to make investing accessible to everyone, so from day one our goal has been to enable novices or seasoned experts to invest in the cryptocurrencies they want with easy-to-use financial products.",
            lint4: "IDEX strictly abides by the laws and regulations of various regions. We operate our businesses based on various VASP registrations, as well as MiFID II, E-Money and PSD II licences amongst all our core markets. ",
            lint5: "IDEX is one of the fastest growing fintech companies. We have a world-class expert team and the best technical talents to ensure that users' digital assets are always their own and to consolidate our position in the cryptocurrency trading market.",
        },
        billTypes: [
            {v: 101, name:'충전'},
            {v: 102, name:'입금'},
            {v: 103, name:'동결'},
            {v: 104, name:'해동'},
            {v: 105, name:'추가'},
            {v: 106, name:'추가'},
            {v: 201, name:'현금인출 동결'},
            {v: 202, name:'공제'},
            {v: 203, name:'현금 인출 성공'},
            {v: 204, name:'현금 인출 실패'},
            {v: 205, name:'현금 인출 수수료'},
            {v: 206, name:'내보내기'},
            {v: 207, name:'전입'},
            {v: 208, name:'코인 내보내기'},
            {v: 209, name:'코인 전입'},
            {v: 301, name:'코인 수수료'},
            {v: 302, name:'계약 수익'},
            {v: 303, name:'계약 손실'},
            {v: 311, name:'구매 옵션'},
            {v: 312, name:'옵션 수익'},
            {v: 313, name:'옵션 반환'},
            {v: 314, name:'옵션 수수료'},
            {v: 315, name:'코인 매입 동결'},
            {v: 316, name:'코인 매입 공제'},
            {v: 317, name:'코인 매입 반환'},
            {v: 318, name:'코인 매입'},
            {v: 319, name:'코인 매입'},
            {v: 320, name:'코인 판매 동결'},
            {v: 321, name:'코인 매출 공제'},
            {v: 322, name:'코인 판매 반환'},
            {v: 323, name:'코인이 입금되었습니다'},
            {v: 324, name:'코인이 입금되었습니다'},
            {v: 325, name:'코인 수수료'},
            {v: 401, name:'광산 장비 가입'},
            {v: 402, name:'광산 반환'},
            {v: 403, name:'광산 기계 수입'},
            {v: 404, name:'광산 종료'},
            {v: 405, name:'광산 종료 수수료'},
            {v: 501, name:'블라인드 구매'},
            {v: 502, name:'블라인드 박스 수익'},
        ],
        withdrawTypes: [
            {v: 0, name:'신청 중'},
            {v: 1, name:'처리 중'},
            {v: 2, name:'성공'},
            {v: 3, name:'실패'},
        ],
        types: [
            { v: 4, name: '옵션 계좌' },
            { v: 3, name: '프랑스 통화 계좌' },
            { v: 2, name: '계약 계정' },
            { v: 1, name: '통화 계정' },
        ],
        login: {
            aboutUs: "회사 소개",
            notice: "간편하게 서비스를 충전하려면 온라인 고객 서비스에 문의하세요.",
            login: "로그인",
            selectLanguage: "언어 선택",
            placeEmail: "이메일 또는 모바일",
            placePsd: "은어",
            noAccount: "계좌가 없습니까?가",
            register: "등록",
            btnLogin: "로그인",
            forget: "잊다?",
        },
        register: {
            register: "등록",
            selectLanguage: "언어 선택",
            placeEmail: "이메일",
            placePsd: "은어",
            placeCrmPsd: "암호 확인",
            placeInviteId: "초대 코드",
            haveAccount: "이미 계좌가 있나요?가",
            login: "로그인",
            btnRegister: "등록",
            getCode: "인증 코드 가져오기",
            code: "인증 코드",
            psd_error: "비밀번호를 다시 입력하세요.",
            psd_error2: "일관되지 않은 두 개의 비밀번호",
            email_error: "사서함은 비워둘 수 없습니다!",
            email_error2: "이메일 형식이 잘못되었습니다!",
            elail_register: "이메일",
            phone_register: "전화기",
            country: "나라",
            placePhone: "전화기",
            countryCode: "국가 코드",
            pleaseEnter: "입력하십시오."
        },
        forget: {
            title: "암호를 잊었습니다.",
            labelEmail: "이메일",
            labelEmailCode: "이메일 인증 코드",
            placeEmailCode: "이메일 인증 코드",
        },
        forgetPay: {
            title: "거래 암호를 잊었습니다.",
        }
    },
    Blind_box: {
        Blind_box_record: {
            title: "복권 기록",
        },
        Blind_box_voucher: {
            title: "상품권",
            voucher: "상품권",
            currentVoucher: "현재 자격 증명",
        },
        Blind_box: {
            title: "블라인드 박스",
            goodluck: "행운을 빕니다.",
            panelTit: "아래에서 블라인드 상자를 선택하십시오.",
            tip1: "1회 추첨은 2500장의 쿠폰을 획득해야 합니다",
            tip2: "넌 아직 2500번의 기회가 있어",
            limit1: "오늘 남은 추첨",
            limit2: "10회",
            times: "횟수",
            draw: "그림",
            discount: "할인",
            payable: "대처했어",
            blind_box: "블라인드 박스",
            available: "획득 가능",
            lotteryRecord: "복권 기록",
            Determine_blind_box: "블라인드 확인",
            tip3: "2. 1회 복권 추첨은 매일 10회, 연속 추첨은 5회 제한 없다",
            tip4: "3. 쿠폰 교환은 EXCHANG에서 BTC와 ETH로 교환할 수 있습니다.",
            tip5: "4. 복권은 매번 당첨된다",
            tip6: "5. 10000회 이상 5회 USDT 출금은'교환권', 5000회 이상 10회 USDT 출금은'교환권', 2500회 이상 20회 USDT 추첨은'교환권'을 받아야 한다.",
            tip7: "5달러 복권 보너스와 확률:",
            tip8: "1. 15~200 OTD, 확률 50%",
            tip9: "2. EOS 1~5개, 확률은 10%",
            tip10: "3. 5~30XRP, 확률 10%",
            tip11: "4. 1~10 USDT, 확률은 10%",
            tip12: "5. 10~50DOGE, 확률 10%",
            tip13: "6. 3 ETH, 확률은 5%",
            tip14: "7. 10 ETH, 확률 2%",
            tip15: "8. 20ETH, 확률은 2%",
            tip16: "9. 2BTC, 확률 1%",
            tip17: "10 USDT 추첨 보너스 및 확률:",
            tip18: "1. 30~600OTD, 확률 50%",
            tip19: "2. 1~20EOS, 확률은 10%",
            tip20: "3. 10~80XRP, 확률 10%",
            tip21: "4. 톤당 5~30달러, 확률 10%",
            tip22: "5. 20~100DOGE, 확률 10%",
            tip23: "6. 20 ETH, 확률 4%",
            tip24: "7. 1BTC, 확률 4%",
            tip25: "8. 2BTC, 확률 2%",
            tip26: "20 USDT 추첨 보너스 및 확률:",
            tip27: "1. 5~50USDT, 확률 30%",
            tip28: "2. 1~50 ADA, 확률 30%",
            tip29: "3. 1~20EOS, 확률은 10%",
            tip30: "4. 3DOT, 확률은 5%",
            tip31: "5. XMR 1개, 확률 5%",
            tip32: "6. 1 BCH, 5% 확률",
            tip33: "7. 1 ETH, 확률은 5%",
            tip34: "8. 10000 DOGE, 확률 5%",
            tip35: "9. 2BTC, 3% 확률",
            tip36: "10. 5BTC, 확률 2%",
            tip37: "행운 추첨 방식:",
            tip38: "1. 5 USDT 1회 뽑기, 10 USDT 2회 뽑기, 20 USDT 1회 뽑기, 5회 연속 뽑기 5% 할인",
        },
    },
    Financial: {
        index: {
            finance: "자금",
            ieo: "IEO 회사",
            LockMining: "잠금 채광",
            day: "낮",
            unit: "상승",
            Daily_yield: "일일 생산량",
        },
    },
    Follow: {
        index: {
            title: "거래원 명단",
            tip: "데이터가 매시간 업데이트됨",
        },
    },
    Fund: {
        Assets_all_record: {
            title: "재무 기록",
            number: "숫자",
            time: "시간",
        },
        Assets_record: {
            title: "계정 상세 정보",
            total_assets: "전환된 총 자산",
            Available_Credit: "가용 신용",
            locking: "미해결이었어",
            Financial_records: "재무 기록",
            number: "숫자",
            time: "시간",
        },
        Assets: {
            Account_record: "계정 변경 기록",
            Recharge: "충전",
            Collection: "수집",
            Withdrawal: "철회",
            transfer: "이동",
            Currency_transfer: "화폐 이체",
            Coins: "동전",
            contract: "계약",
            Legal_currency: "법정 화폐",
            option: "옵션",
            Equivalent: "동등했어",
        },
        FundTransfer: {
            title: "자금 이전",
            Select_Currency: "통화 선택",
            from: "부터...",
            Transfer_quantity: "전송 수량",
            to: "도착",
        },
        Receive_withdraw_record: {
            title: "기록",
        },
        Receive: {
            title: "충전",
            Digital_currency: "디지털 화폐",
            tip: "고객센터에 연락하여 충전 주소를 문의하다",
            next: "다음 단계",
            Recharge_address: "충전 주소",
            Recharge_number: "충전 수량",
            place_Recharge_number: "충전 수량을 입력하세요",
            Payment_voucher: "결제 자격 증명",
            place_Payment_voucher: "결제 자격 증명을 입력하십시오.",
        },
        Transfer: {
            Currency_transfer: "화폐 이체",
            Capital_transfer: "자금 이전",
            from: "부터...",
            Transfer_number: "전송 수량",
            need_service_charge: "추가 서비스 요금",
            to: "도착",
            will_get: "얻게 될 거야",
            Available_Credit: "가용 신용",
            Exchange_rate: "환율",
            Service_Charge: "서비스 요금",
        },
        Withdraw: {
            title: "계좌 이체",
            Withdrawal: "출금",
            bank_card: "은행 카드",
            wallet_address: "지갑 주소 언급",
            Withdrawal_number: "출금 수량",
            available: "사용 가능",
            place_Withdrawal_number: "출금 수량을 입력하세요",
            all: "전부",
            Minimum_Withdrawal_number: "최소 출금 수량",
            password: "계정 비밀번호",
            Service_Charge: "수수료",
        },
    },
    Guess: {
        title: "추측",
        min: "최소",
        Bullish: "상승",
        Bearish: "하락",
        Guess_amount: "추측 금액",
        place_Guess_amount: "금액을 입력하세요",
        Current_selection: "현재 선택",
        Payable: "지불 가능",
        Guess_now: "지금 추측",
        Available: "사용 가능",
        Lottery_record: "복권 기록",
        Betting_record: "베팅 기록",
        Start_price: "시작 가격",
        End_price: "종료 가격",
        Game_introduction: "게임 소개",
        tip1: "1. 퀴즈는 10분, 3분, 1분으로 나뉘어져 있습니다. BTC의 상승과 하락. BTC의 가격은 코인베이스+바이낸스+후오비의 평균 가격입니다. 이것은 완전히 공정한 소설입니다." ,
        tip2: "2. 10분을 예로 들면, 각 기간의 첫 10분은 주문, 두 번째 2분은 입찰, 마지막 1분은 입찰 가격 발표, 입찰 > 입찰 가격은 '업'입니다. 예를 들어 00:00~00:15의 첫 번째 기간은 00:00에 1000 USDT에 BTC '콜' 주문을 하고 시간이 00:13에 도달하면 BTC 가격이 35000 USDT이고 표시된 가격은 35000 USDT입니다. 그리고 00:14에 게시된 결과 가격은 35001 USDT이므로 '상승'이며, 정확히 맞히면 '강세'입니다. 추측을 축하합니다. 950달러의 수익을 받게 됩니다. 시스템에서 1950 USDT로 정산하며, 이 중 50 USDT가 플랫폼이며 950 USDT의 수익을 얻게 됩니다." ,
        팁3: "3. 확률은 1:0.95인데, 왜 1:1이 아닌가요? 플랫폼에서 5%의 수수료를 부과하기 때문입니다." ,
        팁4: "4. 퀴즈의 결과는 퀴즈가 끝나면 정산됩니다. 이긴 상금은 패자가 가져갑니다. 플랫폼이 사용자 간의 도박에 관여하지 않도록 하기 위해 승자가 많고 패자가 적은 경우, 상금을 전달하기 전에 패자가 나타날 때까지 기다려야 합니다. 예를 들어 100,000 USDT를 걸고 이겼다고 가정하면 95,000 USDT를 받아야 하지만, 80,000 USDT만 잃고 배송 풀에 95,000 USDT를 지급할 돈이 충분하지 않다면 배송 풀에서 패자의 돈을 보여줄 때까지 기다려야 합니다. 15,000 USDT에 도달하면 플랫폼에서 95,000 보너스를 정산합니다." ,
        tip5: "5. 정말 기다릴 수 없고 보너스를 빨리 받고 싶다면 빠른 배송을 할 수 있지만, 빠른 배송은 95000 USDT의 25%만 받게 되므로 빠른 배송은 일반 보너스와 동일하므로 2375 USDT만 받을 수 있습니다. 권한은 플랫폼으로 이전되고 플랫폼은 상대적인 위험을 감수해야 하므로 플랫폼은 막대한 수수료를 공제해야 합니다." ,
        tip6: "이 Q&A는 플랫폼과 사용자 간의 베팅에 관한 것이 아니며, 이러한 상황을 발견하면 즉시 신고할 수 있습니다." ,
    },
    home: {
        title: "도구상자",
        Total_assets: "전체 자산 변환",
        Recharge: "충전",
        documentary: "문서",
        transaction: "거래",
        IEO: "IEO 회사",
        Lock_mining: "마이닝 잠금",
        Investment_Financing: "투자 자금 조달",
        Currency_transfer: "화폐 이전",
        Currency_swap: "교환",
        Withdrawal: "출금",
        Blind_box: "블라인드 박스",
        Guess: "추측",
        Daily_tasks: "일",
        Sign_task: "서명 작업",
        Buy_coins_quickly: "빠르게 코인 구매",
        Buy_quickly: "빠르게 구매",
        day: "일",
        rise: "상승",
        Daily_yield: "일일 수익",
        quotation: "견적",
        Transaction_pair: "거래 쌍",
        Latest_price: "최신 가격",
        Rise_fall: "전원 기립",
    },
    ieo: {
        ieo: "IEO 구독",
        Lock_up_cycle: "잠금 주기",
        day: "일",
        Closed: "종료됨",
        Completed: "완료됨",
        Subscribed: "구독됨",
        surplus: "잉여",
        My_subscription: "내 구독",
        Subscription_time: "구독 시간",
        currency: "화폐",
        Requested_number: "요청 수량",
        Pass_number: "통과 수",
        Market_time: "시장 시간",
    },
    Invest: {
        conduct: "금융 거래 수행",
        Special_area: "특별 지역",
        Annualized_rate: "연환산 이율",
        Linked_price: "연동 가격",
        Remaining_share: "잔여 주식",
        Position_duration: "포지션 기간",
        due_date: "만기일",
        My_conduct: "내 금융 관리",
        all: "모두",
        fail: "실패",
        Purchased: "구매 완료",
        settlement: "프로토콜",
        Completed: "완전한",
    },
    Lockming: {
        Minimum_single: "최소 단일 거래",
        Lock_up_cycle: "잠금 주기",
        payout_time: "지급 시간",
        everyday: "매일",
        Custody_funds: "보관 자금",
        Return_expiration: "만기 시 반환",
        called_away: "호출됨",
        placeholder: "내용을 입력하세요",
        all: "모두",
        Estimated_income: "예상 수익",
        Available_assets: "사용 가능한 자산",
        subscription: "구독",
        Lock_mining: "잠금 마이닝",
        Funds_custody: "보유 중인 자금",
        Estimated_yield_today: "오늘의 예상 수익",
        Accumulated_income: "누적 수익",
        Orders_custody: "보유 중인 주문",
        Delegated_Order: "위임 주문",
        Lock: "잠금",
        day: "일",
        Lock_list: "잠금 목록",
        Exited: "종료됨",
        Redeemed: "상환됨",
        have_hand: "보유 중",
        redeem: "상환",
        Amount_currency: "화폐 금액",
        Daily_yield: "일일 수익",
        Lockup_time: "잠금 시간",
        Expiration_time: "만료 시간",
        early_redemption: "조기 상환 위약금",
    },
    Market: {
        Optional: "선택 가능",
        Transaction_pair: "트랜잭션 쌍",
        Latest_price: "최신 가격",
        place_search: "관심 있는 통화 / 주식 이름 / 코드 검색",
    },
    Setting: {
        label_currency: "통화 선택",
        place_currency: "통화를 선택하십시오.",
        label_walletTitle: "지갑 이름",
        place_walletTitle: "지갑 이름을 입력하십시오.",
        label_walletAddress: "지갑 주소",
        place_walletAddress: "지갑 주소를 입력하십시오.",
        Add_bankCard: "은행 카드 추가",
        label_name: "실명",
        label_depositBank: "계좌 개설 은행",
        label_bankCard: "은행카드 번호",
        label_beneficary_address: "수익자 주소",
        label_payee_name: "수취인 이름",
        label_bank_name: "은행명",
        label_swift_code: "은행 코드",
        APP_download: "MSB 인증",
        label_currentPassword: "현재 암호",
        place_currentPassword: "현재 암호를 입력하십시오.",
        label_newPassword: "새 로그인 암호",
        place_newPassword: "새 로그인 암호를 입력하십시오.",
        label_confirmationPassword: "새 암호 확인",
        place_confirmationPassword: "새 암호를 입력하여 확인하십시오.",
        label_newPassword1: "트랜잭션 비밀번호",
        place_newPassword1: "트랜잭션 비밀번호를 입력하세요",
        payment_notice: "참고: 거래 비밀번호의 초기 기본값은 현재 로그인 비밀번호입니다",
        Real_mark1: "개인 인증",
        Real_mark2: "인증 후 사용 가능한 권한:",
        Real_mark3: "레벨 1 기본 인증",
        Real_mark4: "인증 후 반영 가능, 24시간 최대 200BTC",
        Real_mark5: "인증 후, 법정 통화 거래를 할 수 있으며, 단일 거래 한도는 2000USDT이다",
        Real_mark6: "2단계 고급 인증",
        Real_mark7: "반영 한도 상향, 24시간 한도는 500BTC",
        Real_mark8: "프랑스 화폐의 거래 한도를 높여 단일 한도는 1000000달러이다",
        Real_mark9: "팁: 사용자의 자금 보안을 위해 이메일 및 전화 바인딩을 완료하십시오. 그렇지 않으면 인증이 실패합니다.",
        Real_mark10: "기본 인증",
        Real_mark11: "확인",
        Real_mark12: "고급 인증",
        Real_mark13: "인증 취소",
        label_realName: "실명",
        message_realName: "실제 이름을 입력하십시오.",
        label_cardNumber: "주민등록번호",
        message_cardNumber: "주민등록번호를 입력하십시오.",
        upload_front: "신분증 정면 업로드",
        upload_back: "신분증 뒷면을 올리다",
        upload_people: "휴대용 신분증 업로드",
        under_review: "검토 중",
        security_setting: "보안 설정",
        my_bank: "내 은행카드",
        Change_Loginpsd: "로그인 암호 변경",
        Change_transactionpsd: "거래 암호 수정",
        email: "이메일",
        my: "나의",
        Bank: "은행카드",
        AddBank: "은행 카드 추가",
        walletAddress: "지갑 주소",
        Default_currency: "기본 법정 통화",
        language: "언어",
        share: "공유",
        logout: "로그아웃",
        qrcode: "내 Qrcode",
        personal: "개인 정보",
        identity: "인증",
        nickname: "닉네임",
        nickname_tip: "2~10자, 중국어 지원, 숫자",
        Add_newAccount: "새 계정 추가",
        label_user: "사용자 이름",
        label_number: "은행카드 번호",
        label_branch: "은행 주소",
        label_bank: "은행명",
        label_swift: "Swift 인코딩",
        label_address: "사용자 주소",
    },
    Task: {
        daily_tasks: "일상적인 작업",
        activity_today: "오늘 행사",
        task_list: "작업 목록",
        weekly_tasks: "주간 작업",
        activity: "활동",
        undone: "미완성",
        receive: "수신",
        task_rules: "작업 규칙",
        task_rules1: "1: 이벤트가 일주일 동안 누적 중첩되어 매주 월요일 오전 0시에 자동으로 비우고 다시 결제합니다.；",
        task_rules2: "2: 매일 4개의 일상 퀘스트를 모두 완료, 100 이벤트 레벨 달성 후 CCA 코인 5개 획득；",
        task_rules3: "3：이벤트가 350에 도달하면 CCA 코인 10개를 획득할 수 있습니다；",
        task_rules4: "4： 이벤트가 700에 도달하면 N1 광산 기계를 1일 동안 체험할 수 있습니다",
        sign_task: "로그인 작업",
        Sign_everyDay: "매일 로그인",
        CCA_coin: "CCA 코인",
        signIn: "로그인",
        day: "낮",
        daily_checkIn: "매일 탑승 수속을 하다.",
        put_away: "집어치우다",
        Expand: "확대",
        signIn_now: "지금 로그인",
        checkIn_rules: "체크 인 규칙",
        checkIn_rules1: "1：5일 연속 접속하면 CCA 코인 5개 획득；",
        checkIn_rules2: "2：14일 연속 로그인하면 CCA 코인 20개 획득；",
        checkIn_rules3: "3：연속 21일 로그인, 3 일 N1 광기 체험 시간 보상；",
        checkIn_rules4: "4：24일 연속 체크인 후 체크인 기록이 중단되지 않고 매일 체크인하면 CCA 코인 5개를 획득할 수 있습니다；",
    },
    Transaction: {
        delegate_List: "대표 목록",
        risk_rate: "위험률",
        total_income: "직위총수입",
        oneClick_closing: "클릭 한 번으로 닫기",
        optional: "선택 가능",
        all_closed: "모든 위치 닫기",
        flat_multiple: "단일 및 다중 주문만",
        flat_empty: "편평하고 빈 어음만 있어요.",
        purchase: "구매",
        sellOut: "매진",
        market_price: "시장 가격",
        fixed_price: "고정 가격",
        trading_hands: "거래자",
        multiple: "배수",
        bond: "유대",
        Service_Charge: "서비스 요금",
        balance: "균형",
        long: "길다",
        Short: "짧았어",
        Price: "가격",
        number: "숫자",
        type: "유형",
        time: "시간",
        operation: "활동",
        Transaction_pair: "트랜잭션 쌍",
        direction: "방향",
        search: "검색",
        Latest_price: "최신 가격",
        Rise_fall: "상승-하락",
        entrust: "위임",
        all: "전부의",
        entrust_time: "위탁 시간",
        state: "상태",
        Completed: "완전한",
        air_completed: "공기 완성",
        place_amount: "내용을 입력하십시오.",
        turnover: "인사 변경율",
        contract: "계약",
        option: "옵션",
        coins: "동전",
        High_today: "오늘 높아요.",
        Low_today: "오늘 낮은 점",
        Entrusted_bill: "위탁어음",
        Buy_more: "추가 구매",
        Buy_less: "적게 사다",
        buy: "사다",
        sell: "팔다",
        crm_order: "주문 확인",
        Current_price: "현재 가격",
        expiration_time: "만료 시간 선택",
        Option_position: "옵션 위치",
        position: "위치",
        history: "역사",
        Purchase_price: "구매 가격",
        Purchase_amount: "구매 금액",
        Order_duration: "주문 기간",
        Selling_time: "영업 시간",
        Profit_loss: "손익",
        Hand_count: "손수",
        countdown: "카운트다운",
        order_number: "주문 번호",
        currency: "통화",
        reales_Number: "실제 거래",
        total_price: "총 가격",
        close_price: "종가",
        volume_trade: "무역량",
        hand_fee: "수수료",
        continue_order: "주문 계속",
    },
    footer: {
        home: "집",
        market: "장터",
        transaction: "거래",
        financial: "재정적",
        fund: "펀드",
        rule: "규칙",
        select_language: "언어를 선택하십시오."
    },
    common: {
        noData: "아직 관련 데이터가 없습니다",
        Exchange: "교환",
        Cancel: "취소",
        Confirm: "확인",
        Submit: "제출",
        more: "더보기",
        add: "추가",
        Cover: "엄폐",
        Success: "성공",
        portion: "부분",
        day: "낮",
        please_select: "선택하세요",
        no_data: "사용 가능한 데이터 없음",
    },
    script: {
        error_msg: "숫자/문자" ,
        register_noAccount: "사용자 전화 또는 사용자 이메일을 입력하세요!" ,
        send_phone_scuccess: "SMS를 성공적으로 보냈습니다!" ,
        register_success: "등록 성공!" ,
        please_enter_amount: "금액을 입력해주세요!" ,
        please_enter_money: "구매 금액을 입력해주세요!" ,
        success: "성공!" ,
        bankId_msg: "지갑을 선택해주세요" ,
        amount_msg: "금액을 입력해주세요" ,
        password_msg: "비밀번호를 입력하세요" ,
        redemption_msg: "조기 상환을 확인하시겠습니까?" ,
        tip: "팁" ,
        redemption_success: "상환 성공!" ,
        cancelled: "취소됨" ,
        realname_submit_success: "실명 제출 성공!" ,
        logout_tip: "정말 취소하시겠습니까?" ,
        login_tip: "정말 로그인하시겠습니까?" ,
        signIn: "로그인" ,
        closeout_tip: "모든 포지션을 청산하시겠습니까?" ,
        closeout_success: "모든 포지션이 성공적으로 청산되었습니다!" ,
        cancel_tip: "정말 취소하시겠습니까?" ,
        notice: "알림" ,
        insufficient_balance: "잔액이 부족합니다!" ,
    },
};
